import React, { useState, useEffect, useContext, useRef } from "react";
import { Context } from "../../Context/AdsContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import MyAdsItem from "../../components/MyAds/MyAdsItem/MyAdsItem";

import classes from "./MyAds.module.css";

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));
const MyAds = (props) => {
  const isInitialMount = useRef(true);
  const history = useHistory();
  const { state, getUserAds, updateStatus } = useContext(Context);
  console.log(state.newAdsStatus);
  const [noData, setNoData] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(true);
  const progressClasses = useStylesProgress();

  useEffect(() => {
    getUserAds();
    return () => {
      updateStatus("get_user_ads", []);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      
      state.userAdsList.length === 0 && setLoadingStatus(false);
    }, 8000);
  }, [state.userAdsList]);
  console.log(state.userAdsList);
  const handleBackClick = () => {
    history.push("/");
  };
  return (
    <Row className="m-0">
      <Col sm={12} className={"d-flex align-items-center " + classes.Header}>
        <ArrowForwardIcon
          size={24}
          className={classes.BackIcon}
          onClick={handleBackClick}
        />
        <p className="m-0 text-white text-center flex-fill">آگهی های من</p>
      </Col>

      <Col sm={12} className={"py-2 overflow-auto " + classes.Main}>
        <Row>
          {state.userAdsList.length < 1 && loadingStatus ? (
            <div className={"flex-fill text-center pt-3"}>
              <CircularProgress color="primary" classes={progressClasses} />
            </div>
          ) : null}

          {state.userAdsList.length === 0 && !loadingStatus ? (
            <div className={"flex-fill text-center pt-3"}>
              <h6 className={classes.NoData}>لیست آگهی های شما خالی می باشد</h6>
            </div>
          ) : null}

          {state.userAdsList.map((ads) => {
            return (
              <Col sm={12} md={4} key={ads.id.toString()}>
                <MyAdsItem ads={ads} />
              </Col>
            );
          })}
          {/* <div className={'flex-fill text-center pt-3 '}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div> */}
        </Row>
      </Col>
    </Row>
  );
};

export default MyAds;
