import createDataContext from "./createDataContext";
import AmlakApi from "../api/amlak";

const adsReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "get_Consultant_all":
      return { ...state, ConsultantStates: action.payload };
    case "get_category":
      return { ...state, category: action.payload };
    case "get_house_type":
      return { ...state, houseType: action.payload };
    case "get_options":
      return { ...state, options: action.payload };
    case "add_newads":
      return { ...state, newAdsStatus: action.payload };
    case "get_user_subset":
      return { ...state, userSubsetList: action.payload };
    case "get_user_ads":
      return { ...state, userAdsList: action.payload };
    case "reset_ads_list":
      return { ...state, adsList: action.payload };
    case "get_ads_list":
      const ads = state.adsList.concat(action.payload);
      return { ...state, adsList: ads };
    case "update_ads_list":
      return { ...state, adsList: action.payload };
    case "get_ads_details":
      return { ...state, adsDetails: action.payload };
    case "add_favorite_ads":
      return { ...state, addFavoriteAdsStatus: action.payload };
    case "delete_favorite_ads":
      return { ...state, deleteFavoriteAdsStatus: action.payload };
    case "add_last_favorite_id":
      return { ...state, lastFavoriteAdsId: action.payload };
    case "get_favorite_ads_list":
      const favoriteAds = state.favoriteAds.concat(action.payload);
      return { ...state, favoriteAds: favoriteAds };
    case "reset_favorite_ads_list":
      return { ...state, favoriteAds: [] };
    case "favorite_list_fetch_more":
      return { ...state, isFavoriteListFetchMore: action.payload };
    case "last_favorite_list_page":
      return { ...state, favoriteListPage: action.payload };
    case "update_filters":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "update_filter_status":
      if (action.payload === "inactive") {
        let filters = {
          category: "",
          categoryId: "",
          houseType: "",
          subCategoryId: "",
          minPriceTitle: "",
          minPrice: "",
          maxPriceTitle: "",
          maxPrice: "",
          minArea: "",
          maxArea: "",
          Consultant: "",
          filter: [],
        };
        return { ...state, filterStatus: action.payload, filters: filters };
      }
      return { ...state, filterStatus: action.payload };
    case "ads_list_fetch_more":
      return { ...state, isAdsListFetchMore: action.payload };
    case "last_ads_list_scroll":
      return { ...state, lastAdsListScroll: action.payload };
    case "last_ads_list_page":
      return { ...state, adsListPage: action.payload };
    case "is_search_ads":
      return { ...state, isSearchAds: action.payload };
    case "search_ads_query":
      return { ...state, searchQuery: action.payload };
    case "search_ads_page":
      return { ...state, searchAdsListPage: action.payload };
    case "delete_user_ads":
      return { ...state, deleteUserAdsStatus: action.payload };
    case "update_ads_image":
      return { ...state, updateAdsImageStatus: action.payload };
    case "remove_ads_image":
      return { ...state, removeAdsImageStatus: action.payload };
    case "update_ads":
      return { ...state, updateAdsStatus: action.payload };
    case "get_chosen_category":
      return { ...state, chosenCategories: action.payload };
    case "get_home_banners":
      return { ...state, homeBanners: action.payload };
    case "add_visit_request":
      return { ...state, addVisitRequestStatus: action.payload };
    case "update_category_filter_status":
      return { ...state, categoryFilterStatus: action.payload };
    case "active_filter_category":
      return { ...state, activeFilterCategory: action.payload };
    case "add_filter_prices":
      return { ...state, filterPrices: action.payload };
    case "add_filter_areas":
      return { ...state, filterAreas: action.payload };
    default:
      return state;
  }
};
const getCategory = (dispatch) => () => {
  AmlakApi.post(`/Advertisement_registration/get_advertising_type`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({
          type: "get_category",
          payload: response.data.advertising_type,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getHouseType = (dispatch) => (categoryId) => {
  AmlakApi.post(`/Advertisement_registration/get_advertising_house_type`, {
    category_id: categoryId,
  })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({
          type: "get_house_type",
          payload: response.data.advertising_house_type,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getConsultantAll = (dispatch) => () => {
  AmlakApi.post(`Consultant/all`)
    .then((response) => {
      if (response.data.status === "success") {
        console.log(response.data.data);
        dispatch({
          type: "get_Consultant_all",
          payload: response.data.data,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
      console.log(error);
    });
};

const getOptions = (dispatch) => (subCategoryId) => {
  AmlakApi.post(`/Advertisement_registration/get_options`, {
    sub_category_id: subCategoryId,
  })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "get_options", payload: response.data.options });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

function resizeImage(base64, maxWidth, maxHeight, callback) {
  const img = new Image();
  img.src = base64;
  img.onload = function () {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const width = Math.min(maxWidth, img.width);
    const height = Math.min(maxHeight, img.height);

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, 0, 0, width, height);

    const resizedBase64 = canvas.toDataURL("image/jpeg");
    callback(resizedBase64);
  };
}

const addNewAds = (dispatch) => async (adsInfo, adsDescription, pictures) => {
  console.log(adsInfo);
  let images = [];
  for (const picture of pictures) {
    await new Promise((resolve) => {
      resizeImage(picture, 800, 600, (resizedBase64) => {
        const base64 = resizedBase64.split(";base64,");
        images.push(base64[1]);
        resolve();
      });
    });
  }

  const newAds = {
    user_id: localStorage.getItem("id"),
    category_id: adsInfo.categoryId,
    sub_category_id: adsInfo.subCategoryId,
    title: adsInfo.title,
    address: adsInfo.address,
    price: adsInfo.price,
    area: adsInfo.area,
    about: adsDescription.description,
    transaction: adsDescription.transaction,
    picture: images,
  };
  console.log(newAds);
  await AmlakApi.post(
    `/Advertisement_registration/advertisement_registration`,
    newAds
  )
    .then((response) => {
      dispatch({ type: "add_newads", payload: response.data.status });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getUserAds = (dispatch) => () => {
  AmlakApi.post(`/User/my_advertising`, {
    user_id: localStorage.getItem("id"),
    page: 1,
  })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "get_user_ads", payload: response.data.data });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};
const getUserSubset = (dispatch) => () => {
  AmlakApi.post(`/User/subset`, {
    user_id: localStorage.getItem("id"),
    page: 1,
  })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "get_user_subset", payload: response.data.data });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getChosenCategory = (dispatch) => () => {
  AmlakApi.post(`/Advertisement_registration/chosen_categories`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({
          type: "get_chosen_category",
          payload: response.data.chosen_categories,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getAdsList = (dispatch) => (page) => {
  AmlakApi.post(`/Advertisement_registration/get_advertising_list`, {
    user_id: localStorage.getItem("id"),
    page: page,
  })
    .then((response) => {
      if (response.data.status === "success") {
        if (page === 1) {
          dispatch({
            type: "ads_list_fetch_more",
            payload: true,
          });
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }
        dispatch({
          type: "last_ads_list_page",
          payload: page,
        });
        dispatch({
          type: "get_ads_list",
          payload: response.data.advertising_details,
        });
      } else {
        if (page === 1) {
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }

        dispatch({
          type: "ads_list_fetch_more",
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getHomeBanners = (dispatch) => () => {
  AmlakApi.post(`/More/get_all_banners`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({
          type: "get_home_banners",
          payload: response.data.banners,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getFilterAdsList = (dispatch) => (filters, page) => {
  let filter = {
    category_id: filters.categoryId,
    sub_category_id: filters.subCategoryId,
    filter: filters.filter,
    user_id: localStorage.getItem("id"),
    page: page,
  };
 
    filter.min_price = '1';
    filter.max_price = "1000000000000";
  

  if (filters.minArea && filters.maxArea) {
    filter.min_area = filters.minArea;
    filter.max_area = filters.maxArea;
  }
  AmlakApi.post(`/Advertisement_registration/get_filters`, filter)
    .then((response) => {
      if (response.data.status === "success") {
        if (page === 1) {
          dispatch({
            type: "ads_list_fetch_more",
            payload: true,
          });
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }
        if (response.data.variable_filters.length > 0) {
          dispatch({
            type: "last_ads_list_page",
            payload: page,
          });
          dispatch({
            type: "get_ads_list",
            payload: response.data.variable_filters,
          });
        } else {
          dispatch({
            type: "ads_list_fetch_more",
            payload: false,
          });
        }
      } else {
        if (page === 1) {
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }

        dispatch({
          type: "ads_list_fetch_more",
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getCategoryFilterAdsList = (dispatch) => (categoryId, page) => {
  AmlakApi.post(`/Advertisement_registration/get_category_advertising_list`, {
    user_id: localStorage.getItem("id"),
    page: page,
    category_id: categoryId,
  })
    .then((response) => {
      if (response.data.status === "success") {
        if (page === 1) {
          dispatch({
            type: "ads_list_fetch_more",
            payload: true,
          });
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }
        if (response.data.advertising_details.length > 0) {
          dispatch({
            type: "last_ads_list_page",
            payload: page,
          });
          dispatch({
            type: "get_ads_list",
            payload: response.data.advertising_details,
          });
        } else {
          dispatch({
            type: "ads_list_fetch_more",
            payload: false,
          });
        }
      } else {
        if (page === 1) {
          dispatch({
            type: "reset_ads_list",
            payload: [],
          });
        }

        dispatch({
          type: "ads_list_fetch_more",
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getAdsDetails = (dispatch) => (adsId) => {
  AmlakApi.post(`/Advertisement_registration/get_advertising_details`, {
    user_id: localStorage.getItem("id"),
    advertising_id: adsId,
  })
    .then((response) => {
      // console.log(response)
      if (response.data.status === "success") {
        dispatch({
          type: "get_ads_details",
          payload: response.data.advertising_details,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const addVisitRequest = (dispatch) => (adsId) => {
  AmlakApi.post(`/Advertisement_registration/request_viewer`, {
    advertising_id: adsId,
    user_id: localStorage.getItem("id"),
  })
    .then((response) => {
      dispatch({ type: "add_visit_request", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateStatus = (dispatch) => (type, payload) => {
  dispatch({ type: type, payload: payload });
};

const addFavoriteAds = (dispatch) => (adsId) => {
  AmlakApi.post(`/User/add_to_favorites`, {
    user_id: localStorage.getItem("id"),
    advertising_id: adsId,
  })
    .then((response) => {
      dispatch({ type: "add_last_favorite_id", payload: adsId });
      dispatch({ type: "add_favorite_ads", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const deleteFavoriteAds = (dispatch) => (adsId) => {
  AmlakApi.post(`/User/delete_favorites`, {
    user_id: localStorage.getItem("id"),
    advertising_id: adsId,
  })
    .then((response) => {
      dispatch({ type: "add_last_favorite_id", payload: adsId });
      dispatch({ type: "delete_favorite_ads", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateAdsFavoriteStatus = (dispatch) => (adsList, adsId, status) => {
  console.log("delete callllll");
  let ads = [...adsList];
  const itemIndex = adsList.findIndex((ads) => ads.id === adsId);
  ads[itemIndex].is_like = status;
  dispatch({
    type: "update_ads_list",
    payload: ads,
  });
};

const getFavoriteAdsList = (dispatch) => (page) => {
  AmlakApi.post(`/User/favorite_list`, {
    user_id: localStorage.getItem("id"),
    page: page,
  })
    .then((response) => {
      if (response.data.status === "success") {
        if (page === 1) {
          dispatch({
            type: "favorite_list_fetch_more",
            payload: true,
          });
          dispatch({
            type: "get_favorite_ads_list",
            payload: [],
          });
        }
        dispatch({
          type: "last_favorite_list_page",
          payload: page,
        });
        dispatch({
          type: "get_favorite_ads_list",
          payload: response.data.data,
        });
      } else {
        dispatch({
          type: "get_favorite_ads_list",
          payload: [],
        });
        dispatch({
          type: "favorite_list_fetch_more",
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getSearchAdsList = (dispatch) => (search, page) => {
  AmlakApi.post(`/Advertisement_registration/get_search_advertising_list`, {
    search: search,
    page: 1,
  })
    .then((response) => {
      console.log(response.data);
      if (response.data.status === "success") {
       
          console.log("omad")
          dispatch({ type: "reset_ads_list", payload: [] });
          dispatch({ type: "is_search_ads", payload: true });
          dispatch({ type: "lastAdsListScroll", payload: 0 });
          dispatch({ type: "ads_list_fetch_more", payload: true });
         
   
        dispatch({
          type: "get_ads_list",
          payload: response.data.advertising_details,
        });
      } else {
        console.log("nayomad")
        dispatch({
          type: "reset_ads_list",
          payload: [],
        });
        dispatch({
          type: "ads_list_fetch_more",
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const deleteUserAds = (dispatch) => (adsId) => {
  AmlakApi.post(`/Advertisement_registration/delete_advertising`, {
    user_id: localStorage.getItem("id"),
    advertising_id: adsId,
  })
    .then((response) => {
      dispatch({ type: "delete_user_ads", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateAdsPicture = (dispatch) => (adsId, picture) => {
  const base64 = picture.split(";base64,");
  AmlakApi.post(`/Advertisement_registration/insert_picture`, {
    advertising_id: adsId,
    picture: base64[1],
  })
    .then((response) => {
      dispatch({ type: "update_ads_image", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const removeAdsPicture = (dispatch) => (pictureId) => {
  AmlakApi.post(`/Advertisement_registration/delete_picture`, {
    picture_id: pictureId,
  })
    .then((response) => {
      dispatch({ type: "remove_ads_image", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateAds = (dispatch) => (adsId, adsInfo, adsDescription) => {
  const newAds = {
    user_id: localStorage.getItem("id"),
    category_id: adsInfo.categoryId,
    sub_category_id: adsInfo.subCategoryId,
    title: adsInfo.title,
    address: adsInfo.address,
    area: adsInfo.area,
    about: adsDescription.description,
    advertising_id: adsId,
    transaction: adsDescription.transaction,
  };
  AmlakApi.post(`/Advertisement_registration/advertisement_update`, newAds)
    .then((response) => {
      dispatch({ type: "update_ads", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getPriceList = (dispatch) => (adsId) => {
  AmlakApi.post(`/More/get_all_prices`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "add_filter_prices", payload: response.data.prices });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getAreaList = (dispatch) => (adsId) => {
  AmlakApi.post(`/More/get_all_areas`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "add_filter_areas", payload: response.data.areas });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

export const { Provider, Context } = createDataContext(
  adsReducer,
  {
    getCategory,
    getHouseType,
    getOptions,
    addNewAds,
    getUserAds,
    getAdsList,
    getAdsDetails,
    updateStatus,
    addFavoriteAds,
    deleteFavoriteAds,
    updateAdsFavoriteStatus,
    getFavoriteAdsList,
    getSearchAdsList,
    getFilterAdsList,
    deleteUserAds,
    updateAdsPicture,
    removeAdsPicture,
    updateAds,
    getChosenCategory,
    getHomeBanners,
    addVisitRequest,
    getCategoryFilterAdsList,
    getPriceList,
    getAreaList,
    getUserSubset,
    getConsultantAll,
  },
  {
    errorMessage: "",
    category: [],
    houseType: [],
    options: [],
    newAdsStatus: "",
    userAdsList: [],
    adsList: [],
    adsDetails: {
      details: {
        id: "",
        user_id: "",
        category_id: "",
        sub_category_id: "",
        title: "",
        address: "",
        area: "",
        price: "",
        about: "",
        is_like: "",
        base_options: [],
      },
      pictures: [],
      option_and_attributes: [],
      option_and_attributes_define: [],
    },
    addFavoriteAdsStatus: "",
    lastFavoriteAdsId: "",
    deleteFavoriteAdsStatus: "",
    favoriteAds: [],
    filterStatus: "inactive",
    filters: {
      category: "",
      categoryId: "",
      houseType: "",
      subCategoryId: "",
      minPriceTitle: "",
      minPrice: "",
      maxPriceTitle: "",
      maxPrice: "",
      minArea: "",
      maxArea: "",
      filter: [],
    },
    isAdsListFetchMore: true,
    lastAdsListScroll: "",
    adsListPage: 1,
    isSearchAds: false,
    searchQuery: "",
    searchAdsListPage: 0,
    isFavoriteListFetchMore: true,
    lastFavoriteListScroll: "",
    favoriteListPage: 1,
    deleteUserAdsStatus: "",
    updateAdsImageStatus: "",
    removeAdsImageStatus: "",
    updateAdsStatus: "",
    chosenCategories: [],
    homeBanners: [],
    addVisitRequestStatus: "",
    categoryFilterStatus: "inactive",
    activeFilterCategory: "",
    filterPrices: [],
    filterAreas: [],
  }
);
