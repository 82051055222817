import React,{useState,useEffect,useContext,useRef} from "react";
import {Context} from '../../Context/AdsContext'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import MyAdsItem from "../../components/MyAds/MyAdsItem/MyAdsItem";

import classes from "../MyAds/MyAds.module.css";


const useStylesProgress = makeStyles((theme) => ({
    colorPrimary: {
        color: '#2979FF'
    },
}));
const MySubset = (props) => {
    const isInitialMount = useRef(true);
    const history = useHistory();
    const {state,getUserAds,updateStatus,getUserSubset} = useContext(Context);

    const [noData,setNoData] = useState(false);
    const [loadingStatus,setLoadingStatus] = useState(true);
    const progressClasses = useStylesProgress();

    useEffect(()=>{
        getUserSubset();
        return ()=>{
            updateStatus('get_user_subset',[])
        }
    },[])

    useEffect(()=>{
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            setLoadingStatus(false);
        }

    },[state.userSubsetList])

    const handleBackClick = ()=>{
        history.push('/');
    }
    return (

        <Row className="m-0">
            <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
                <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />
                <p className='m-0 text-white text-center flex-fill'>زیر مجموعه های من</p>

            </Col>

            <Col sm={12} className={"py-2 overflow-auto " + classes.Main}>

                <Row>


                    {
                         <div className={'flex-fill text-center pt-3'}>
                            <h6 className={classes.NoData}>لیست زیر مجموعه های شما خالی می باشد</h6>
                        </div>


                    }
                </Row>
            </Col>
        </Row>
    );
};

export default MySubset;
