import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InstallBanner from "../../components/InstallBanner/InstallBanner";
import { Context } from "../../Context/AuthContext";
import { FiUser } from "react-icons/fi";
import { ContactPhone } from "@material-ui/icons";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import HomeIcon from "@material-ui/icons/Home";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import AdsScreen from "../../components/Ads/AdsList";
import FavoritesScreen from "../../components/Favorites/Favorites";
import ProfileScreen from "../../components/Profile/Profile";
import Counseling from "./../../components/Counseling/Counseling";

import classes from "./Home.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: "gray",
    "&$selected": {
      color: "#2979FF",
      outline: 0,
    },
  },
  label: {
    fontSize: 10,
    "&$selected": {
      fontSize: 10,
    },
  },
  selected: {},
}));

const Home = (props) => {
  const { state, updateInstallBannerStatus, updateActiveBottomNavigation } =
    useContext(Context);
  const [installBannerStatus, setInstallBannerStatus] = useState(false);
  const [value, setValue] = useState(state.activeBottomNavigation);

  let location = useLocation();

  const history = useHistory();

  const bottomstyle = useStyles();

  const handleChange = (event, newValue) => {
    if (newValue === 2) {
      if (localStorage.getItem("id")) {
        history.push("/new-ads");
      } else {
        history.push("/auth/verfication-number");
      }
    } else {
      updateActiveBottomNavigation(newValue);
      setValue(newValue);
    }
  };

  useEffect(() => {
    if (
      !state.installBannerStatus &&
      isIOS &&
      !window.matchMedia("(display-mode: standalone)").matches
    ) {
      updateInstallBannerStatus(true);
      setInstallBannerStatus(true);
    }
  }, []);

  return (
    <section className={classes.Section}>
      {installBannerStatus ? (
        <InstallBanner continue={() => setInstallBannerStatus(false)} />
      ) : null}
      <TabPanel value={value} index={0}>
        <AdsScreen />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FavoritesScreen />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProfileScreen />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Counseling />
      </TabPanel>
      <div className={classes.BottomNavigation}>
        <Card className={"w-100 "}>
          <BottomNavigation value={value} onChange={handleChange}>
            <BottomNavigationAction
              classes={bottomstyle}
              value={0}
              label="آگهی ها"
              icon={<HomeIcon size={20} />}
              showLabel={true}
            ></BottomNavigationAction>
            <BottomNavigationAction
              classes={bottomstyle}
              value={1}
              label="علاقه مندی"
              icon={<FavoriteBorderIcon size={20} />}
              showLabel={true}
            />
            <BottomNavigationAction
              classes={bottomstyle}
              value={2}
              label="ثبت آگهی"
              icon={<AddCircleOutlineIcon size={20} />}
              showLabel={true}
            />
            {/* <BottomNavigationAction
              classes={bottomstyle}
              value={4}
              label="مشاوران"
              icon={<ContactPhone size={20} />}
              showLabel={true}
            /> */}
            <BottomNavigationAction
              classes={bottomstyle}
              value={3}
              label="پروفایل"
              icon={<FiUser size={20} />}
              showLabel={true}
            />
          </BottomNavigation>
        </Card>
      </div>
    </section>
  );
};

export default Home;
