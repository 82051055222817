import React,{useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";

import { FiSend } from "react-icons/fi";
import { FiInbox } from "react-icons/fi";


import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Inbox from '../../components/Ticket/Inbox/Inbox';
import NewTicket from '../../components/Ticket/NewTicket/NewTicket';

import classes from "./Ticket.module.css";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
            children
        )}
      </div>
    );
  }

const useStyles = makeStyles((theme) => ({
    root: {
        color: "gray",
        "&$selected": {
        color: "#2979FF",
        outline: 0
        }
      },
      label:{
          fontSize: 10,
          "&$selected": {
            fontSize: 10,
          }
      },
      selected: {}
  }));
  
  
const Ticket = (props) => {
    const [value, setValue] = useState(0);
    const bottomstyle = useStyles();


  const handleChange = (event, newValue) => {
      setValue(newValue)
  };


  return (
    <section className={classes.Section}>
    <TabPanel value={value} index={0}>
        <Inbox />
      </TabPanel>
      <TabPanel value={value} index={1}>
      <NewTicket />
      </TabPanel>
      <div className={classes.BottomNavigation}>
      <Card className={'w-100 '}>
        <BottomNavigation
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction 
          
          classes={bottomstyle} value={0} label="صندوق ورودی" icon={<FiInbox size={20} />} showLabel={true}>
              
          </BottomNavigationAction>
          <BottomNavigationAction classes={bottomstyle}  value={1} label="ارسال پیام" icon={<FiSend size={20} />} showLabel={true}/>
        </BottomNavigation>
      </Card>
      </div>
    </section>
  );
};


export default Ticket;
