import React, { Component } from "react";
import {
  createTheme ,
  StylesProvider,
  jssPreset,
  ThemeProvider,
} from "@material-ui/core/styles";

import { create } from "jss";
import rtl from "jss-rtl";

import Container from "react-bootstrap/Container";
import 'swiper/swiper.scss';

import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import "./Layout.css";

const theme = createTheme ({
  typography: {
    fontFamily: "IranSans",
  },
  direction: "rtl",
});

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
class Layout extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <main>
            <Container fluid={true} className='p-0'>{this.props.children}</Container>
          </main>
        </StylesProvider>
      </ThemeProvider>
    );
  }
}

export default Layout;
