const checkLogin = () => {
  const localStorageNames = localStorage.getItem("firstname");
  if (localStorageNames) {
    return true;
  } else {
    return false;
  }
};

export default checkLogin;
