import createDataContext from "./createDataContext";
import AmlakApi from "../api/amlak";

const authReducer = (state, action) => {
  switch (action.type) {
    case "add_error":
      return { ...state, errorMessage: action.payload };
    case "add_code":
      return { ...state, status: action.payload };
    case "add_phone_number":
      return { ...state, phoneNumber: action.payload };
    case "check_code":
      return { ...state, checkCodeStatus: action.payload };
    case "signup":
      return { ...state, registerStatus: action.payload };
    case "signout":
      return { token: null, errorMessage: "" };
    case "add_states":
      return { ...state, states: action.payload };
    case "add_cities":
      return { ...state, cities: action.payload };
    case "update_profile":
      return { ...state, updateProfileStatus: action.payload };
    case "user_profile":
      return { ...state, userProfile: action.payload };
    case "install_banner_status":
      return { ...state, installBannerStatus: action.payload };
    case "active_bottom_navigation":
      return { ...state, activeBottomNavigation: action.payload };
    case "add_ticket_status":
      return { ...state, addTicketStatus: action.payload };
    case "get_tickets":
      return { ...state, ticketList: action.payload };
    case "add_active_ticket":
      return { ...state, activeTicket: action.payload };
    case "get_ticket_details":
      return { ...state, ticketDetails: action.payload };
    case "add_ticket_reply":
      return { ...state, addTicketReplyStatus: action.payload };
    case "update_ticket_details":
      let details = [...state.ticketDetails];
      details.push(action.payload);
      return { ...state, ticketDetails: details };

    default:
      return state;
  }
};

const addCode = (dispatch) => (number) => {
  AmlakApi.post(`/Activation/add_sms`, { phone: number })
    .then((response) => {
      dispatch({ type: "add_code", payload: response.data.status });
      dispatch({ type: "add_phone_number", payload: number });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const checkCode = (dispatch) => (number, code) => {
  AmlakApi.post(`/Activation/check_activation_sms`, {
    phone: number,
    code: code,
  })
    .then((response) => {
      console.log(response);
      if (response.data.status === "success") {
        localStorage.setItem("id", response.data.data[0].id);
        localStorage.setItem("firstname", response.data.data[0].first_name);
        localStorage.setItem("lastname", response.data.data[0].last_name);
        localStorage.setItem("phone", response.data.data[0].phone);
        localStorage.setItem("state", response.data.data[0].state);
        localStorage.setItem("city", response.data.data[0].city);
      }
      dispatch({ type: "check_code", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const signup =
  (dispatch) => async (firstName, lastName, phoneNumber, state, city) => {
    console.log(phoneNumber)
    AmlakApi.post(`/user/register_user`, {
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
      state: state,
      city: city,
      set_code: null,
    })
      .then((response) => {
        if (response.data.status === "success") {
          localStorage.setItem("id", response.data.user_id);
          localStorage.setItem("firstname", firstName);
          localStorage.setItem("lastname", lastName);
          localStorage.setItem("phone", phoneNumber);
          localStorage.setItem("state", state);
          localStorage.setItem("city", city);
        }
        dispatch({ type: "signup", payload: response.data.status });
      })
      .catch((error) => {
        dispatch({
          type: "add_error",
          payload: "Something went wrong with get code",
        });
      });
  };

const updateProfile = (dispatch) => async (newUser, image) => {
  let profileImage = "";
  if (image.change) {
    profileImage = image.image.split(";base64,")[1];
  }
  AmlakApi.post(`/user/update_profile`, {
    first_name: newUser.name,
    last_name: newUser.lastName,
    state: newUser.province,
    city: newUser.city,
    user_id: localStorage.getItem("id"),
    profile_picture: profileImage,
  })
    .then((response) => {
      if (response.data.status === "success") {
        localStorage.setItem("firstname", newUser.name);
        localStorage.setItem("lastname", newUser.lastName);
        localStorage.setItem("state", newUser.province);
        localStorage.setItem("city", newUser.city);
      }
      dispatch({ type: "update_profile", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getStates = (dispatch) => () => {
  AmlakApi.post(`/User/get_all_states`)
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "add_states", payload: response.data.states });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getCities = (dispatch) => (stateId) => {
  AmlakApi.post(`/User/get_cities`, { state_id: stateId })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "add_cities", payload: response.data.cities });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const getUser = (dispatch) => () => {
  AmlakApi.post(`/user/profile`, { user_id: localStorage.getItem("id") })
    .then((response) => {
      if (response.data.status === "success") {
        dispatch({ type: "user_profile", payload: response.data.data[0] });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const signout = (dispatch) => () => {
  localStorage.setItem("id", "");
  localStorage.setItem("firstname", "");
  localStorage.setItem("lastname", "");
  localStorage.setItem("phone", "");
  localStorage.setItem("state", "");
  localStorage.setItem("city", "");
};

const updateInstallBannerStatus = (dispatch) => (status) => {
  dispatch({ type: "install_banner_status", payload: status });
};

const updateActiveBottomNavigation = (dispatch) => (index) => {
  dispatch({ type: "active_bottom_navigation", payload: index });
};

const addTicket = (dispatch) => (subject, message) => {
  AmlakApi.post(`/User/private_messege`, {
    user_id: localStorage.getItem("id"),
    subject: subject,
    message: message,
  })
    .then((response) => {
      dispatch({ type: "add_ticket_status", payload: response.data.status });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateAddTicketStatus = (dispatch) => (status) => {
  dispatch({ type: "add_ticket_status", payload: status });
};

const getTicketList = (dispatch) => () => {
  AmlakApi.post(`/User/messeges`, { user_id: localStorage.getItem("id") })
    .then((response) => {
      dispatch({ type: "get_tickets", payload: response.data.messages });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const setActiveTicket = (dispatch) => (ticket) => {
  dispatch({ type: "add_active_ticket", payload: ticket });
};

const getTicketDetail = (dispatch) => (message_thread_code) => {
  AmlakApi.post(`/User/messege_detaiels`, {
    user_id: localStorage.getItem("id"),
    message_thread_code: message_thread_code,
  })
    .then((response) => {
      dispatch({ type: "get_ticket_details", payload: response.data });
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateTicketDetails = (dispatch) => (details) => {
  dispatch({ type: "get_ticket_details", payload: details });
};

const replyTicket = (dispatch) => (message_thread_code, message) => {
  AmlakApi.post(`/User/private_messege_reply`, {
    user_id: localStorage.getItem("id"),
    message_thread_code: message_thread_code,
    message: message,
  })
    .then((response) => {
      dispatch({ type: "add_ticket_reply", payload: response.data.status });
      if (response.data.status === "success") {
        const newTicket = {
          message_id: "0",
          message_thread_code: message_thread_code,
          message: message,
          sender: localStorage.getItem("id"),
          timestamp: "",
          read_status: null,
        };
        dispatch({ type: "update_ticket_details", payload: newTicket });
      }
    })
    .catch((error) => {
      dispatch({
        type: "add_error",
        payload: "Something went wrong with get code",
      });
    });
};

const updateReplyTicketStatus = (dispatch) => (status) => {
  dispatch({ type: "add_ticket_reply", payload: status });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    addCode,
    checkCode,
    signup,
    getStates,
    getCities,
    updateProfile,
    getUser,
    signout,
    updateInstallBannerStatus,
    updateActiveBottomNavigation,
    addTicket,
    updateAddTicketStatus,
    getTicketList,
    setActiveTicket,
    getTicketDetail,
    updateTicketDetails,
    replyTicket,
    updateReplyTicketStatus,
  },
  {
    errorMessage: "",
    phoneNumber: "",
    status: "",
    checkCodeStatus: "",
    registerStatus: "",
    states: [],
    cities: [],
    updateProfileStatus: "",
    userProfile: {
      id: 0,
      phone: "",
      first_name: "",
      last_name: "",
      state: "",
      city: "",
      profile_picture: "",
      state_id: "",
      city_id: "",
    },
    installBannerStatus: false,
    activeBottomNavigation: 0,
    addTicketStatus: "",
    ticketList: [],
    activeTicket: "",
    ticketDetails: [],
    addTicketReplyStatus: "",
  }
);
