import React, { useState, useEffect } from "react";
import AmlakApi from "../../../api/amlak";
import CounselingCard from "./CounselingCard";

const CounselingProducts = () => {
  const [getConsultant, setConsultant] = useState([]);

  useEffect(() => {
    getAllConsultantData();
  }, []);

  const getAllConsultantData = async () => {
    try {
      await AmlakApi.post(`Consultant/all`)
        .then(async (response) => {
          if (response?.data.status === "success") {
            await setConsultant(response?.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="row g-4 d-flex justify-content-center aling-item-center">
        {getConsultant.map((elements) => {
          console.log(elements);
          return (
            <div className="">
              <CounselingCard
                key={elements.id}
                elementsId = {elements.id}
                images={elements.profile_picture}
                phone={elements.phone}
                firtsNames={elements.first_name}
                lastNames={elements.last_name}
                descriptions = {elements.description}
                city = {elements.city}
                state = {elements.state}
     />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default CounselingProducts;

// city: "تبريز"
// ​
// description: "توضیحات مشاوره"
// ​
// first_name: "محسن"
// ​
// id: "1"
// ​
// last_name: "یوسفی"
// ​
// phone: "09368021790"
// ​
// profile_picture: "https://webapp.vetel.ir/img/logo.png"
// ​
// state: "آذربایجان شرقی"
// ​
// status: "1"
