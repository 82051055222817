import React,{useState,useContext,useEffect} from "react";
import Textfield from "../../../components/Ui/Input/Input";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from '../../../Context/AdsContext';
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "react-bootstrap/Card";
import { useSnackbar } from 'notistack';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from './AdsInfo.module.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const AdsInfo = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  console.log(props)
  const {state,getCategory,getHouseType} = useContext(Context);
  const [adsInfo,setAdsInfo] = useState({
    category: '',categoryId: props.value.details.category_id,houseType: '',
    subCategoryId: props.value.details.sub_category_id,title: props.value.details.title,
    address: props.value.details.address,area: props.value.details.area
  })
  const [showAdsTypeSlide, setShowAdsTypeSlide] = useState("AuthProvinceCardClose ");
  const [showTypeHouseSlide, setShowTypeHouseSlide] = useState("AuthProvinceCardClose ");
  const [showBackDrop,setShowBackDrop] = useState(false);
  const backDropClasses = useStyles();


  useEffect(()=>{
    getCategory();
    getHouseType(adsInfo.categoryId);
  },[]);

  useEffect(()=>{
    if(props.isCategoryFirst){
      if(state.category.length > 0){
        const categoryTitle = state.category.filter(item=>item.id === adsInfo.categoryId);
        setAdsInfo({...adsInfo,category: categoryTitle[0].category});
        props.onChange({isCategoryFirst: false});
      }
    }
  },[state.category]);

  useEffect(()=>{
    if(props.isSubCategoryFirst){
      if(state.houseType.length > 0){
        const subCategoryTitle = state.houseType.filter(item=>item.id === adsInfo.subCategoryId);
        setAdsInfo({...adsInfo,houseType: subCategoryTitle[0].sub_category});
        props.onChange({isSubCategoryFirst: false});
      }
    }
  },[state.houseType]);

  useEffect(()=>{
    props.onChange(adsInfo);
  },[adsInfo]);


  const handleCategoryOnClick = (category,categoryId) =>{
    getHouseType(categoryId);
    setShowAdsTypeSlide("AuthProvinceCardClose ")
    setShowBackDrop(!showBackDrop);
    setAdsInfo({...adsInfo,category: category ,categoryId: categoryId});
  }

  
  const handleHouseTypeOnClick = (houseType,subCategoryId) =>{
    setShowTypeHouseSlide("AuthProvinceCardClose ")
    setShowBackDrop(!showBackDrop);
    setAdsInfo({...adsInfo,houseType: houseType ,subCategoryId: subCategoryId});
  }

  const handleTitle = (value) =>{
    const titleA = p2e(value.target.value);
    const title = a2e(titleA);
    setAdsInfo({...adsInfo,title: title});
  }

  
  const handleAddress = (value) =>{
    const addressA = p2e(value.target.value);
    const address = a2e(addressA);
    setAdsInfo({...adsInfo,address: address});
  }

  
  const handleArea = (value) =>{
    const areaA = p2e(value.target.value);
    const area = a2e(areaA);
    setAdsInfo({...adsInfo,area: area});
  }

  return (
  <Row className={classes.Row}>
      <Col lg={6} sm={12}>
    <Textfield label="نوع آگهی" value={adsInfo.category}  customSelect className="w-100 my-2" 
    InputProps={{
      readOnly: true,
    }}
    onClick={()=>{
      setShowBackDrop(true);
      setShowAdsTypeSlide("AuthProvinceCardOpen ");
    }} />

      </Col>
      <Col lg={6} sm={12}>
      <Textfield label="نوع مسکن" value={adsInfo.houseType} readOnly customSelect className="w-100 my-2"
      InputProps={{
        readOnly: true,
      }}
       onClick={()=>{
      setShowBackDrop(true);
      setShowTypeHouseSlide("AuthProvinceCardOpen ");
    }}/>
      </Col>

      <Col lg={6} sm={12}>
      <Textfield label="عنوان" value={adsInfo.title} onChange={handleTitle} className="w-100 my-2" />
      </Col>

      
      <Col lg={6} sm={12}>
      <Textfield label="آدرس" value={adsInfo.address} onChange={handleAddress} multiline className="w-100 my-2" />
      </Col>
{/* 
      <Col lg={6} sm={12}>
      <Textfield label="آدرس" value={adsInfo.price} onChange={handleAddress} type='tel' className="w-100 my-2" />
      </Col> */}


      <h6 className='text-right w-100 font-weight-bold my-2 px-3 '>مشخصات</h6>
      <Col lg={6} sm={12}>
      <Textfield label="متراژ" unit='متر مربع' value={adsInfo.area} onChange={handleArea} type='tel' className="w-100 my-2" />
      </Col>
      

      <Col >
      <ButtonPrimary
        className="btn w-25 my-3 float-left"
        onClick={()=>{
          if(adsInfo.categoryId && adsInfo.subCategoryId && adsInfo.title && adsInfo.address && 
            adsInfo.area){
          props.setStep(2)
        }else{
          enqueueSnackbar('همه اطلاعات را تکمیل کنید',{variant: 'error'})
        }
        }}
      >
        ادامه
      </ButtonPrimary>
      </Col>

      <Backdrop
  className={backDropClasses.backdrop}
  open={showBackDrop}
  onClick={() =>
    {
    setShowAdsTypeSlide("AuthProvinceCardClose ")
    setShowTypeHouseSlide("AuthProvinceCardClose ")
    setShowBackDrop(!showBackDrop);
  }
  }
>
</Backdrop>

<Card className={"pt-4 " + showAdsTypeSlide + classes.SlideCard}>
  <h5 className='text-center text-secondary mx-4 pb-3 border-bottom'>نوع آگهی</h5>
  {state.category.map((item,index)=>{
    return <h6 className='text-center my-2' onClick={()=>handleCategoryOnClick(item.category,item.id)} key={index}>{item.category}</h6>
  })}
  </Card>

  
<Card className={"pt-4 " + showTypeHouseSlide + classes.SlideCard}>
  <h5 className='text-center text-secondary mx-4 pb-3 border-bottom'>نوع مسکن</h5>
  {state.houseType.map((item,index) =>{
    return <h6 className='text-center my-2' onClick={()=>handleHouseTypeOnClick(item.sub_category,item.id)} key={index}>{item.sub_category}</h6>
  })}
  </Card>
  </Row>
    );
};

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
export default AdsInfo;
