import React, { useState, useEffect, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/AdsContext";

import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";

import PickImagePlaceHolder from "../../../assets//images/newads-back-image.png";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreImage from "../../../assets/images/more-image-newads.png";
import Textfield from "../../../components/Ui/Input/Input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import Card from "react-bootstrap/Card";
import CloseIcon from "@material-ui/icons/Close";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./AdsDescription.module.css";

const useStylesBackDrop = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));

const AdsDescription = (props) => {
  const history = useHistory();
  const { state, getOptions, addNewAds } = useContext(Context);
  const [adsInfo, setAdsInfo] = useState(props.adsInfo);
  const [adsDescription, setAdsDescription] = useState({
    description: "",
    transaction: [],
  });
  const inputFile = useRef(null);
  const [images, setImages] = useState([]);
  const [imageNumber, setImageNumber] = useState([0, 1, 2, 3, 4]);
  const [activePick, setActicePick] = useState(0);
  const [showSlide, setShowSlide] = useState("AuthProvinceCardClose ");
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [activeOption, setActiveOption] = useState(0);
  const [isNewAds, setIsNewAds] = useState(false);
  const progressClasses = useStylesProgress();
  const backDropClasses = useStylesBackDrop();

  useEffect(() => {
    getOptions(adsInfo.subCategoryId);
  }, []);

  useEffect(() => {
    console.log(state.newAdsStatus);
    setIsNewAds(false);
    if (state.newAdsStatus === "success") {
      history.push("/my-ads");
      state.newAdsStatus = "";
    }
  }, [state.newAdsStatus]);

  // const handleImageChange = (event) => {
  //   getBase64(event.target.files[0], (callback) => {
  //     const imageList = [...images];
  //     imageList[activePick] = callback;
  //     setImages([...imageList]);
  //   });
  // };

  function resizeImage(base64, maxWidth, maxHeight, callback) {
    const img = new Image();
    img.src = base64;
    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
  
      const width = Math.min(maxWidth, img.width);
      const height = Math.min(maxHeight, img.height);
  
      canvas.width = width;
      canvas.height = height;
  
      ctx.drawImage(img, 0, 0, width, height);
  
      const resizedBase64 = canvas.toDataURL("image/jpeg");
      callback(resizedBase64);
    };
  }
  
// Modify handleImageChange to include resizing
const handleImageChange = (event) => {
  const selectedFile = event.target.files[0];

  if (selectedFile) {
    getBase64(selectedFile, (base64) => {
      resizeImage(base64, 800, 600, (resizedBase64) => {
        setImages([...images, resizedBase64]);
      });
    });
  }
};
  const handleRemoveImage = (removedItemIndex) => {
    const imageList = images.filter(
      (item, index) => removedItemIndex !== index
    );
    setImages([...imageList]);
  };

  const handlePickImage = () => {
    inputFile.current.click();
  };

  const handleBoolTextFieldClick = (index) => {
    setActiveOption(index);
    setShowBackDrop(!showBackDrop);
    setShowSlide("AuthProvinceCardOpen ");
  };

  const handleAddOptionText = (
    index,
    optionId,
    stringValue,
    intValue,
    boolValue
  ) => {
    let newTransaction = adsDescription.transaction;
    newTransaction[index] = {
      option_id: optionId,
      attribute_string: stringValue,
      attribute_int: intValue,
      attribute_bool: boolValue,
    };
    setAdsDescription({ ...adsDescription, transaction: newTransaction });
  };

  const handleAddOptionPick = (
    index,
    optionId,
    stringValue,
    intValue,
    boolValue
  ) => {
    let newTransaction = adsDescription.transaction;
    newTransaction[index] = {
      option_id: optionId,
      attribute_string: stringValue,
      attribute_int: intValue,
      attribute_bool: boolValue,
    };
    setAdsDescription({ ...adsDescription, transaction: newTransaction });
    setShowSlide("AuthProvinceCardClose ");
    setShowBackDrop(false);
  };

  const handleChangeDescription = (value) => {
    const descriptionA = p2e(value.target.value);
    const description = a2e(descriptionA);
    setAdsDescription({ ...adsDescription, description: description });
  };
  return (
    <Row className={"p-0 " + classes.Row}>
      <h6 className={`text-right font-weight-bold px-3 my-2 ${classes.wrap}`}>
        عکس آگهی
      </h6>
      <h6 className={"text-right px-3 my-2 " + classes.HeaderDescription}>
        عکس هایی از داخل و خارج از ملک اضافه کنید
        <br />
        آگهی های دارای عکس تا 3 برابر بیشتر از آگهی های دیگر دیده میشوند
      </h6>
      <Col lg={12} className="mt-2 text-center">
        <Card
          className={classes.ImageBorder}
          onClick={() => {
            setActicePick(0);
            handlePickImage();
          }}
        >
          {images[0] ? (
            <DeleteOutlineIcon
              size={12}
              className={classes.RemoveImageIcon}
              onClick={() => handleRemoveImage(0)}
            />
          ) : null}

          {images[0] ? (
            <img src={images[0]} className="w-100" height={250} />
          ) : (
            <img
              src={PickImagePlaceHolder}
              className="w-50 mx-auto"
              height={150}
            />
          )}
        </Card>
      </Col>
      <input
        type="file"
        className="d-none"
        ref={inputFile}
        onChange={handleImageChange}
      />

      <Col lg={12} className="px-1">
        <Row className="m-0 px-2">
          {imageNumber.map((item, index) => {
            if (index !== 0) {
              return (
                <Col xs={3} key={index} className="mt-2 px-1">
                  <Card
                    className={classes.ImageBorder}
                    onClick={() => {
                      setActicePick(index);
                      handlePickImage();
                    }}
                  >
                    {images[index] ? (
                      <DeleteOutlineIcon
                        size={10}
                        className={classes.RemoveImageIcon}
                        onClick={() => handleRemoveImage(index)}
                      />
                    ) : null}

                    {images[index] ? (
                      <img src={images[index]} className="w-100" height={80} />
                    ) : (
                      <img
                        src={PickImagePlaceHolder}
                        className="w-100"
                        height={80}
                      />
                    )}
                  </Card>
                </Col>
              );
            }
          })}
        </Row>
      </Col>
      <Col lg={12} className="mt-3">
        <Card
          className={
            "d-flex flex-row justify-content-center py-1 " + classes.MoreImage
          }
          onClick={() => setImageNumber([...imageNumber, imageNumber.length])}
        >
          <img src={MoreImage} alt="moreee" />
          <h6 className="m-0 mr-1">انتخاب تصویر بیشتر</h6>
        </Card>
      </Col>

      <h6 className="text-right font-weight-bold px-3 my-3 w-100">ویژگی ها</h6>

      {state.options.length === 0 ? (
        <div className={"w-100 mb-2 text-center d-block pt-3"}>
          <CircularProgress color="primary" classes={progressClasses} />
        </div>
      ) : null}

      {state.options.map((option, index) => {
        if (option.type === "1") {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                label={option.name}
                value={
                  adsDescription.transaction[index]
                    ? adsDescription.transaction[index].attribute_string
                    : ""
                }
                className="w-100 my-2"
                onChange={(value) => {
                  const valueA = p2e(value.target.value);
                  const valueP = a2e(valueA);
                  handleAddOptionText(index, option.id, valueP, null, null);
                }}
              />
            </Col>
          );
        } else if (option.type === "2") {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                label={option.name}
                value={
                  adsDescription.transaction[index]
                    ? adsDescription.transaction[index].attribute_int
                    : ""
                }
                type="tel"
                className="w-100 my-2"
                onChange={(value) => {
                  const valueA = p2e(value.target.value);
                  const valueP = a2e(valueA);
                  handleAddOptionText(index, option.id, null, valueP, null);
                }}
              />
            </Col>
          );
        } else {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                InputProps={{
                  readOnly: true,
                }}
                label={option.name}
                customSelect
                onClick={() => handleBoolTextFieldClick(index)}
                className="w-100 my-2"
                value={
                  adsDescription.transaction[index]
                    ? adsDescription.transaction[index].attribute_bool
                    : ""
                }
              />
            </Col>
          );
        }
      })}

      <h6 className="text-right w-100 font-weight-bold px-3 mt-3 mb-2">
        توضیحات آگهی
      </h6>
      <h6 className={"text-right px-3 my-2 " + classes.HeaderDescription}>
        هر موردی که نیاز داره بهش اشاره کنید رو بنویسید تا مخاطب سوالات خود را
        سریع تر دریافت کند
      </h6>

      <Col lg={6} sm={12}>
        <Textfield
          label="توضیحات"
          multiline
          rows={4}
          className="w-100 my-2"
          value={adsDescription.description}
          onChange={(value) => handleChangeDescription(value)}
        />
      </Col>

      <Col>
        {isNewAds ? (
          <div className={"w-25 mb-2 text-center float-left pt-3"}>
            <CircularProgress color="primary" classes={progressClasses} />
          </div>
        ) : (
          <ButtonPrimary
            className="btn w-25 my-3 float-left"
            onClick={() => {
              setIsNewAds(true);
              addNewAds(adsInfo, adsDescription, images);
            }}
          >
            تایید
          </ButtonPrimary>
        )}
      </Col>

      <Backdrop
        className={backDropClasses.backdrop}
        open={showBackDrop}
        onClick={() => {
          setShowSlide("AuthProvinceCardClose ");
          setShowBackDrop(!showBackDrop);
        }}
      ></Backdrop>

      <Card className={"pt-4 " + showSlide + classes.SlideCard}>
        <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
          {state.options.length > 0 ? state.options[activeOption].name : ""}
        </h5>
        <h6
          className="text-center my-2"
          onClick={() => {
            handleAddOptionPick(
              activeOption,
              state.options[activeOption].id,
              null,
              null,
              "بله"
            );
          }}
        >
          بله
        </h6>
        <h6
          className="text-center my-2"
          onClick={() => {
            handleAddOptionPick(
              activeOption,
              state.options[activeOption].id,
              null,
              null,
              "خیر"
            );
          }}
        >
          خیر
        </h6>
      </Card>
    </Row>
  );
};

function getBase64(file, callback) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result);
  };
  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
}

const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default AdsDescription;
