import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import "./Counseling-products.css";

const CounselingCard = ({
  elementsId,
  images,
  phone,
  firtsNames,
  lastNames,
  descriptions,
  city,
  state,
}) => {
  return (
    <>
      <Card style={{ width: "18rem" }}>
        <Card.Img variant="top" src={images} />
        <Card.Body>
          <Card.Title>{`${firtsNames} ${lastNames}`}</Card.Title>
          <Card.Text>{descriptions}</Card.Text>

          <Link to={`/counseling-top/${elementsId}`}>
            <Button variant="primary">اطلاعات بیشتر</Button>
          </Link>
        </Card.Body>
      </Card>
    </>
  );
};

export default CounselingCard;
