import React,{useState,useContext,useEffect,useRef} from "react";
import { useHistory,Redirect } from "react-router-dom";
import { useSnackbar } from 'notistack';
import { Context } from "../../../Context/AuthContext";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from '../../../assets/images/avatar.png'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Textfield from "../../../components/Ui/Input/Input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";


import classes from "./EditProfile.module.css";


const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  
  
const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));
const EditProfile = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [elementShowStatus,setElementShowStatus] = useState({button: 'd-block',progress: 'd-none'})
  const progressClasses = useStylesProgress();
  const inputFile = useRef(null);
  const history = useHistory();
  const { state,getStates,getCities,getUser,updateProfile } = useContext(Context);
  const [userInfo,setUserInfo] = useState({
    name: state.userProfile.first_name,
    lastName: state.userProfile.last_name,
    province: state.userProfile.state_id,
    city: state.userProfile.city_id,
    provinceLabel: state.userProfile.state,cityLabel: state.userProfile.city,
  profilePicture: ''})
  const [profilePicture,setProfilePicture] = useState({change: false, image: Avatar})
  const [showProvince, setShowProvince] = useState("AuthProvinceCardClose ");
  const [showCity, setShowCity] = useState("AuthCityCardClose ");
  const [showBackDrop,setShowBackDrop] = useState(false);
  const backClasses = useStyles();

  
  useEffect(() => {
    getUser();
    getStates();
  }, []);


  useEffect(() => {
    if(state.userProfile.state_id){
      setCities(state.userProfile.state_id)
    }
    setUserInfo({
      name: state.userProfile.first_name,
      lastName: state.userProfile.last_name,
      province: state.userProfile.state_id,
      city: state.userProfile.city_id,
      provinceLabel: state.userProfile.state,cityLabel: state.userProfile.city,
      profilePicture: state.userProfile.profile_picture})
  }, [state.userProfile]);

  const setCities = (stateId) =>{
    getCities(stateId);
  };

  useEffect(() => {
    setElementShowStatus({button: 'd-block',progress: 'd-none'})
    if(state.updateProfileStatus === "success"){
      getUser();
      enqueueSnackbar('بروزرسانی اطلاعات با موفقیت انجام شد',{variant: 'success'});
      //<Redirect to="/profile/edit" />
  }else if(state.updateProfileStatus === "no_update"){
    enqueueSnackbar('خطا در بروزرسانی اطلاعات مجددا تلاش فرمایید',{variant: 'error'})
  }
  }, [state.updateProfileStatus]);
 

  const handleChangeName = (value)=>{
    setUserInfo({...userInfo,name: value.target.value})
  }

  const handleChangeLastName = (value)=>{
    setUserInfo({...userInfo,lastName: value.target.value})
  }
  
  const handlePickImage = () => {
    inputFile.current.click();
  };

  
  const handleImageChange = (event) => {
    getBase64(event.target.files[0],callback=>{
      setProfilePicture({change: true,image: callback});
    })
   
  };

  const handleUpdateProfile = ()=>{
    setElementShowStatus({button: 'd-none',progress: 'd-block'})
    updateProfile(userInfo,profilePicture);
  }

  const handleBackClick = ()=>{
    history.push('/');
}
  return (
    <Row className="m-0">
      <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
      <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />
      <p className='m-0 text-white text-center flex-fill'>ویرایش حساب کاربری</p>

      </Col>

      <Col sm={12} className={"py-2 px-0 "+classes.Main}>
      <Row className={"m-0 "}>
        <Col sm={12} lg={6}>
              <Textfield label="نام" value={userInfo.name} onChange={handleChangeName} className="w-100 mt-4 mb-2" />
        </Col>

        <Col sm={12} lg={6}>
              <Textfield label="نام خانوادگی" value={userInfo.lastName} onChange={handleChangeLastName} className="w-100 my-2"/>
        </Col>
        <Col sm={12} lg={6}>
        <div
        className='my-2'
                onClick={() =>{
                  setShowBackDrop(!showBackDrop)
                  setShowProvince("AuthProvinceCardOpen " )
                }
                }
              >
                <Textfield 
                customSelect
                value={userInfo.provinceLabel}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="استان"
                  className="w-100"
                />
              </div>
        </Col>

        <Col sm={12} lg={6}>

        <div
        className='my-2'
                onClick={() =>
                  {
                    if(userInfo.province){
                      setShowBackDrop(true);
                      setShowCity("AuthCityCardOpen ")
                    }
                  }
                }
              >
                <Textfield 
                customSelect
                value={userInfo.cityLabel}
                disabled={userInfo.province ? false : true}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="شهر"
                  className="w-100"
                />
              </div>
        </Col>

        <h6 className='font-weight-bold w-100 text-right px-3 my-3'>تصویر پروفایل</h6>

        <Col className='d-flex flex-row mt-2'>
        <div className={'w-50 text-right '+classes.ProfileImage} onClick={handlePickImage}>
          {userInfo.profilePicture && !profilePicture.change ? <img src={`https://amlaki.vaovan.ir/asset/user_profile/${userInfo.profilePicture}`} width={100} height={100}/> :
            <img src={profilePicture.image} width={100} height={100}/>
          }
            <p className='text-secondary text-right mt-2'>آپلود تصویر پروفایل</p>
        </div>

        <input
        type="file"
        className="d-none"
        ref={inputFile}
        onChange={handleImageChange}
      />

        <div className='flex-fill'>
        <div className={'text-left pt-3 my-4 ml-4 '+elementShowStatus.progress}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div>
        <ButtonPrimary className={"w-75 my-5 float-left "+elementShowStatus.button}
        onClick={handleUpdateProfile}>
                ذخیره
              </ButtonPrimary>
        </div>
        
        </Col>



        <Backdrop
          className={backClasses.backdrop}
          open={showBackDrop}
          onClick={() =>
            {
            setShowCity("AuthCityCardClose " )
            setShowProvince("AuthProvinceCardClose ")
            setShowBackDrop(!showBackDrop);
          }
          }
        >
        </Backdrop>

        <Card className={"pt-4 " + showProvince + classes.AuthCityCard}>
          {state.states.map(item =>{
            return <div key={item.id.toString()} onClick={()=>{
              setShowProvince("AuthProvinceCardClose " );
              setShowBackDrop(!showBackDrop);
              setUserInfo({...userInfo,province: item.id,provinceLabel: item.state})
              setCities(item.id)
            }}><h6 className='text-center my-2' style={{cursor: 'pointer'}}>{item.state}</h6></div>
          })}
          </Card>

          <Card className={"pt-4 " + showCity + classes.AuthCityCard}>
          {state.cities.map(item =>{
            return <div key={item.id.toString()} onClick={()=>{
              setShowCity("AuthCityCardClose " );
              setShowBackDrop(!showBackDrop);
              setUserInfo({...userInfo,city: item.id,cityLabel: item.city})
            }}><h6 className='text-center my-2' style={{cursor: 'pointer'}}>{item.city}</h6></div>
          })}
          </Card>
        
      </Row>
    
      </Col>

      
    </Row>
  );
};


function getBase64(file,callback) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    callback(reader.result)
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };
}

export default EditProfile;
