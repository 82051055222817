import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import classes from './Input.module.css'
const textFieldStyles = makeStyles({
  root: {
    "& label.Mui-focused": {
      color: "#2979FF",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#2979FF",
      },
    },
  },
});

const Input = (props) => {
  const textFieldClasses = textFieldStyles();

  return (
    <div className='position-relative'>
    {props.customSelect ? <i className={classes.Arrow}></i> : null}
  {props.unit ? <span className={classes.Unit}>{props.unit}</span> : null}

    <TextField
      variant="outlined"
      size="small"
      {...props}
      className={props.className+' '+textFieldClasses.root}
    />
    </div>
  );
};

export default Input;
