import React,{useState,useEffect,useContext} from "react";
import { useHistory } from "react-router-dom";
import Textfield from "../../../components/Ui/Input/Input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import {Context} from '../../../Context/AuthContext'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import classes from "./NewTicket.module.css";


const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));
const NewTicket = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {state,addTicket,updateAddTicketStatus} = useContext(Context);
  const [elementShowStatus,setElementShowStatus] = useState({button: 'd-block',progress: 'd-none'})

  const [subject,setSubject] = useState('');
  const [message,setMessage] = useState('');
  const progressClasses = useStylesProgress();

  useEffect(()=>{
    setElementShowStatus({button: 'd-block',progress: 'd-none'})
    if(state.addTicketStatus === 'success'){
    enqueueSnackbar('پیام با موفقیت ارسال شد',{variant: 'success'});
    updateAddTicketStatus('');
    setSubject('');
    setMessage('');
    }else if(state.addTicketStatus === 'failed'){
    enqueueSnackbar('خطا در ارسال پیام ! مجددا تلاش فرمایید',{variant: 'error'});
    }
  },[state.addTicketStatus])

  const handleBackClick = ()=>{
    history.goBack();
}

const handleChangeSubject = (value)=>{
  const subjectA = p2e(value.target.value);
  const subject = a2e(subjectA);
  setSubject(subject);
}

const handleChangeMessage = (value)=>{
  const messageA = p2e(value.target.value);
  const message = a2e(messageA);
  setMessage(message);
}

const handleSendClick = ()=>{
  setElementShowStatus({button: 'd-none',progress: 'd-block'})
  if(subject && message){
    addTicket(subject,message)
  }else{
    setElementShowStatus({button: 'd-block',progress: 'd-none'})
    enqueueSnackbar('همه اطلاعات را وارد کنید',{variant: 'error'})
  }
}
  return (
    <Row className="m-0">
      <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
      <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />
      <p className='m-0 text-white text-center flex-fill'>ارسال پیام جدید</p>

      </Col>

      <Col sm={12} className={"pt-2 overflow-auto " + classes.Main} >
      <Row>
      <h6 className='text-right w-100 font-weight-bold my-2 px-3 '>ارسال پیام</h6>
      <Col lg={6} sm={12}>
      <Textfield label="عنوان پیام" value={subject} type='text' className="w-100 my-2" onChange={handleChangeSubject} />
      </Col>

      <Col lg={6} sm={12}>
      <Textfield label="متن پیام"
      value={message}
      multiline
      rows={5}
      type='text' 
      onChange={handleChangeMessage}
      className="w-100 my-2" />
      </Col>
      <Col lg={6} sm={12}>
      <div className={'text-center pt-3 '+elementShowStatus.progress}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div>
      <ButtonPrimary
                className={"w-100 mt-3 "+elementShowStatus.button}
                onClick={handleSendClick}
              >
                ارسال پیام
              </ButtonPrimary> 
      </Col>
       
        </Row>
      </Col>
    </Row>
  );
};

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
export default NewTicket;
