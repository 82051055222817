import React from "react";
import Button from "react-bootstrap/Button";

const ButtonPrimary = (props) => (
  <Button
    variant="amlak-primary"
    className={props.className}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
);

export default ButtonPrimary;
