import React from "react";
import Layout from "./hoc/Layout/Layout";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthNumber from "./containers/Auth/VerficationNumber/VerficationNumber";
import AuthCode from "./containers/Auth/VerficationCode/VerficationCode";
import AuthInfo from "./containers/Auth/VerficationInfo/VerficationInfo";
import Home from "./containers/Home/Home";
import Ads from "./containers/Ads/Ads";
import NewAds from "./containers/NewAds/NewAds";
import EditAds from "./containers/EditAds/EditAds";
import AdsFilter from "./containers/AdsFilter/AdsFilter";
import MyAds from "./containers/MyAds/MyAds";
import MyAdsDetails from "./containers/MyAds/MyAdsDetails/MyAdsDetails";
import EditProfile from "./containers/Profile/EditProfile/EditProfile";
import AboutUs from "./containers/AboutUs/AboutUs";
import Ticket from "./containers/Ticket/Ticket";
import TicketDetail from "./components/Ticket/Inbox/InboxDetail/InboxDetail";
import MySubset from "./containers/MyList/MySubset";
import Counseling from "./components/Counseling/Counseling";
import ConsilingPages from "./components/Counseling/Counsiling-pages/CounsilingPages";
import CheckUserValidations from "./hooks/checkUserValidations";

function App() {

  // CheckUserValidations ()

  return (
    <Layout>
      <Switch>
        <Route path="/ads/:adsId" component={Ads} />
        <Route path="/search" component={AdsFilter} />
        <Route path="/MySubset" component={MySubset} />
        <Route path="/new-ads" exact component={NewAds} />
        <Route path="/my-ads" exact component={MyAds} />
        <Route path="/my-ads/:adsId" exact component={MyAdsDetails} />
        <Route path="/edit-ads" exact component={EditAds} />
        <Route path="/profile/edit" component={EditProfile} />
        <Route path="/aboutus" component={AboutUs} />
        <Route path="/counseling" component={Counseling} />
        <Route path="/counseling-top/:id" component={ ConsilingPages} />
        <Route
          path="/auth/verfication-number"
          render={(props) => <AuthNumber {...props} />}
        />
        <Route path="/auth/verfication-code" component={AuthCode} />
        <Route path="/auth/verfication-info" component={AuthInfo} />
        <Route path="/ticket" exact component={Ticket} />
        <Route path="/ticket/detail" component={TicketDetail} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    </Layout>
  );
}

export default App;
