import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Context } from "../../../Context/AdsContext";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineRightCircle } from "react-icons/ai";
import AdsImage from "../../../assets/images/ads-detail-image.png";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CheckIcon from "@material-ui/icons/Check";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import { addCommas } from "../../../utils/addCommas";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import Card from "react-bootstrap/Card";

import classes from "./MyAdsDetails.module.css";
import { calculatTimes } from "../../../hooks/calculatTimes";

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MyAdsDetails = (props) => {
  const history = useHistory();
  const [deleteDialogStatus, setDeleteDialogStatus] = useState(false);
  const {
    state,
    getAdsDetails,
    updateStatus,
    addFavoriteAds,
    deleteFavoriteAds,
    deleteUserAds,
  } = useContext(Context);
  const { adsId } = useParams();
  const progressClasses = useStylesProgress();

  useEffect(() => {
    getAdsDetails(adsId);
    return () => {
      const newAds = {
        details: {
          id: "",
          user_id: "",
          category_id: "",
          sub_category_id: "",
          title: "",
          address: "",
          area: "",
          price: "",
          about: "",
          is_like: "",
          base_options: [],
        },
        pictures: [],
        option_and_attributes: [],
      };
      updateStatus("get_ads_details", newAds);
      updateStatus("delete_user_ads", "");
    };
  }, []);

  console.log(state.adsDetails);
  useEffect(() => {
    if (state.addFavoriteAdsStatus === "success") {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 1;
        updateStatus("get_ads_details", { ...adsDetail });
        updateStatus("add_favorite_ads", "");
      }
    } else if (state.deleteFavoriteAdsStatus === "success") {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 0;
        updateStatus("get_ads_details", { ...adsDetail });
        updateStatus("delete_favorite_ads", "");
      }
    }
  }, [state.addFavoriteAdsStatus, state.deleteFavoriteAdsStatus]);

  const handleFavoriteClick = () => {
    if (state.adsDetails.details.is_like === 0) {
      addFavoriteAds(state.adsDetails.details.id);
    } else {
      deleteFavoriteAds(state.adsDetails.details.id);
    }
  };

  const handleClickOpen = () => {
    setDeleteDialogStatus(true);
  };

  const handleClose = () => {
    setDeleteDialogStatus(false);
  };

  const handleDeleteAds = () => {
    updateStatus("delete_user_ads", "loading");
    deleteUserAds(state.adsDetails.details.id);
  };

  useEffect(() => {
    if (state.deleteUserAdsStatus === "success") {
      history.goBack();
    }
  }, [state.deleteUserAdsStatus]);

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

  if (state.adsDetails.details.id) {
    return (
      <section className={classes.Section}>
        <AiOutlineRightCircle
          size={32}
          className={classes.BackIcon}
          onClick={() => history.goBack()}
        />
        <Swiper
          pagination={{ dynamicBullets: true, clickable: true }}
          autoplay={{ delay: 1000 }}
          className={"ads-pagination " + classes.Swiper}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
        >
          {state.adsDetails.pictures.length > 0 ? (
            state.adsDetails.pictures.map((picture) => (
              <SwiperSlide>
                <img
                  src={`https://amlaki.vaovan.ir/asset/pictures/${picture.picture}`}
                  className="w-100"
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img src={AdsImage} />
            </SwiperSlide>
          )}
        </Swiper>

        <Card className={classes.Main}>
          <Card.Body className={classes.MainBody}>
            <div className="d-flex flex-row">
              <h6 className="font-weight-bold">
                {state.adsDetails.details.title}
              </h6>

              <h6 className="flex-fill text-left font-weight-bold">
                {state.adsDetails.details.price !== " "
                  ? addCommas(`تومان :${state.adsDetails.details.price}`)
                  : "قیمت وجود ندارد"}
              </h6>
            </div>
            <p className={"text-right m-0 mt-2 " + classes.Location}>
              <FiMapPin size={12} className="ml-1" />
              {state.adsDetails.details.address}
            </p>
            <div className={"mt-2 " + classes.Time}>
              <p className="d-inline-block">
                {calculatTimes(state.adsDetails.details.start_at)}{" "}
              </p>
              {state.adsDetails.details.is_like === 1 ? (
                <FavoriteIcon
                  size={13}
                  onClick={handleFavoriteClick}
                  color="secondary"
                />
              ) : (
                <FavoriteBorderIcon
                  size={13}
                  onClick={handleFavoriteClick}
                  color="secondary"
                />
              )}
            </div>

            <div
              className={
                "w-100 mt-3 py-2 d-flex flex-row justify-content-center " +
                classes.HeaderInfo
              }
            >
              <p className="m-0">متراژ : {state.adsDetails.details.area}</p>
              <p className="m-0 mx-2 px-2 border-right border-left">
                {state.adsDetails.option_and_attributes[1]
                  ? `سال ساخت : ${state.adsDetails.option_and_attributes[1].attribute_int}`
                  : "سال ساخت وارد نشده است"}
              </p>
              <p className="m-0">
                {state.adsDetails.option_and_attributes[3]
                  ? `اتاق: ${state.adsDetails.option_and_attributes[3].attribute_int}`
                  : "تعداد اتاق وارد نشده است"}
              </p>
            </div>
            <h6 className="text-right mt-3 font-weight-bold">دیگر مشخصات</h6>
            {state.adsDetails.option_and_attributes.map((option) => {
              return (
                <div
                  className={
                    "d-flex flex-row border-bottom pb-2 mt-3 mb-3 " +
                    classes.MainInfo
                  }
                >
                  <p className="m-0 flex-fill text-right">{option.name}</p>
                  <p className="m-0 flex-fill">
                    {option.attribute_int
                      ? option.attribute_int
                      : option.attribute_bool
                      ? option.attribute_bool
                      : option.attribute_string}
                  </p>
                </div>
              );
            })}
            {/* 
          <h6 className='text-right mt-3 font-weight-bold'>امکانات</h6>
          <div className={'justify-content-start border-bottom pb-2 '+classes.Details}>
              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              <p className='d-inline-block w-50 m-0 mb-1'>
              <CheckIcon fontSize='small' className="ml-1 mb-1"/>
              سقف کناف
              </p>

              
              </div> */}

            <h6 className="text-right mt-3 font-weight-bold">توضیحات</h6>
            <p className={"mt-2 text-right " + classes.Location}>
              {state.adsDetails.details.about}
            </p>
          </Card.Body>

          <div
            className={
              "d-flex flex-row justify-content-center " + classes.RequestAction
            }
          >
            <ButtonPrimary
              className={"w-50 ml-2 " + classes.RequestButton}
              onClick={() => history.push("/edit-ads")}
            >
              ویرایش آگهی
            </ButtonPrimary>

            <button
              className={"w-50 " + classes.DeleteButton}
              onClick={handleClickOpen}
            >
              حذف آگهی
            </button>
          </div>
        </Card>

        <Dialog
          open={deleteDialogStatus}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
        >
          <h6 className="px-4 py-3 text-right">
            آیا از حذف آگهی اطمینان دارید ؟
          </h6>
          <DialogActions>
            {state.deleteUserAdsStatus === "loading" ? (
              <CircularProgress
                color="primary"
                className="ml-1 mb-1"
                classes={progressClasses}
              />
            ) : (
              <>
                {" "}
                <ButtonPrimary onClick={handleDeleteAds} className="w-25">
                  بله
                </ButtonPrimary>
                <button
                  onClick={handleClose}
                  className={"w-25 " + classes.DeleteButton}
                >
                  خیر
                </button>{" "}
              </>
            )}
          </DialogActions>
        </Dialog>
      </section>
    );
  } else {
    return (
      <section className={classes.Section}>
        <div className={"flex-fill text-center pt-3 "}>
          <CircularProgress color="primary" classes={progressClasses} />
        </div>
      </section>
    );
  }
};

export default MyAdsDetails;
