import React,{useState,useContext,useEffect} from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CircularProgress from '@material-ui/core/CircularProgress';
import Textfield from "../../../components/Ui/Input/Input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';

import Logo from "../../../assets/images/Logo.png";
import FooterImage from "../../../assets/images/auth-footer.png";

import classes from  "./VerficationNumber.module.css";
import { Footer } from "../../../components/Ui/Footer/Footer";


const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));
const VerficationNumber = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [phoneNumber,setPhoneNumber] = useState('');
  const [elementShowStatus,setElementShowStatus] = useState({button: 'd-block',progress: 'd-none'})
  const { state,addCode } = useContext(Context);
  const progressClasses = useStylesProgress();

  const history = useHistory();

  useEffect(()=>{
    if(localStorage.getItem('id')){
      history.push("/");
    }
  },[])

  useEffect(() => {
    if(state.status === "success"){
      setElementShowStatus({button: 'd-block',progress: 'd-none'})
      history.push("/auth/verfication-code")
  }
  },[state.status]);

  const handleNumber = (value)=>{
    const phoneA = p2e(value.target.value);
    const phone = a2e(phoneA);
    setPhoneNumber(phone);
  }

  const handleSendPhoneNumber = ()=>{
    setElementShowStatus({button: 'd-none',progress: 'd-block'})
    if(phoneNumber.length >= 11){
      addCode(phoneNumber);

    }else{
      setElementShowStatus({button: 'd-block',progress: 'd-none'})
      enqueueSnackbar('شماره موبایل خود را وارد کنید',{variant: 'error'});

    }
  }
  return (
    <section className={classes.Section}>
      <Row className={"justify-content-center m-0 "+classes.Logo}>
        <Col sm={12} lg={4} className="p-0 d-block">
          <div className="d-flex flex-column text-center text-white">
            <img src={Logo} className="mx-auto mt-3" width={100} height={100} />
            <h4 className="my-1">واوان هوم</h4>
            <p>خانه ات را پیدا کن</p>
          </div>
        </Col>
      </Row>

      <Row className={"justify-content-center m-0 "+classes.CardSection}>
        <Col sm={12} lg={5} className="p-0">
          <Card className={"pt-4 "+classes.AuthCard}>
            <div className="w-100 px-4">
              <h5 className="text-right">به واوان خوش آمدید</h5>
              <p className="text-secondary text-right mt-3">
                لطفا شماره تلفن همراه خود را وارد کنید تا کد فعال سازی برای شما
                ارسال شود
              </p>

              <Textfield label="شماره تلفن" value={phoneNumber} type='tel' onChange={handleNumber} className="w-100 mt-2" />
              <div className={'text-center pt-3 '+elementShowStatus.progress}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div>

              <ButtonPrimary
                className={"btn-block mt-3 "+elementShowStatus.button}
                onClick={() => handleSendPhoneNumber()}
              >
                ارسال کد
              </ButtonPrimary>    
              <h6 className="text-secondary mt-3 text-center w-50 mx-auto pb-2" onClick={()=>history.push("/")}>
                ورود بدون ثبت نام
              </h6>
            </div>
            <Footer/>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
export default VerficationNumber;
