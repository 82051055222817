import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/AdsContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ButtonPrimary from "../../components/Ui/Button/ButtonPrimary";
import Filters from "../../components/FilterAds/Filters/Filters";
import SubFilters from "../../components/FilterAds/SubFilters/SubFilter";
import { useSnackbar } from "notistack";

import classes from "./AdsFilter.module.css";

const AdsFilter = (props) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { state, updateStatus } = useContext(Context);
  const [step, setStep] = useState("filter");

  const handleConfirmButtonClick = () => {
    if (step === "filter") {
      updateStatus("reset_ads_list", []);
      updateStatus("last_ads_list_page", 1);
      updateStatus("update_filter_status", "active");
      history.push("/");
    } else if (step === "subfilter-price") {
      if (
        (state.filters.minPrice > 0 && state.filters.maxPrice > 0) ||
        (state.filters.minPrice === "" && state.filters.maxPrice === "")
      ) {
        setStep("filter");
      } else {
        enqueueSnackbar("محدوده قیمت را به درستی انتخاب کنید", {
          variant: "error",
        });
      }
    } else if (step === "subfilter-area") {
      if (
        (state.filters.minArea > 0 && state.filters.maxArea > 0) ||
        (state.filters.minArea === "" && state.filters.maxArea === "")
      ) {
        setStep("filter");
      } else {
        enqueueSnackbar("محدوده متراژ را به درستی انتخاب کنید", {
          variant: "error",
        });
      }
    }
  };

  const handleRemoveFilters = () => {
    updateStatus("reset_ads_list", []);
    updateStatus("last_ads_list_page", 1);
    updateStatus("update_filter_status", "inactive");
    history.push("/");
  };
  return (
    <Row className="m-0 p-0">
      <Col
        sm={12}
        className={"d-flex flex-row align-items-center " + classes.Header}
      >
        <ArrowForwardIcon
          size={10}
          className="ml-2"
          onClick={() =>
            step === "filter" ? history.goBack() : setStep("filter")
          }
        />
        {step === "filter" ? (
          <h6 className="m-0 text-center">فیلتر ها</h6>
        ) : null}
        {step === "subfilter-area" ? (
          <h6 className="m-0 text-center">متراژ</h6>
        ) : null}
        {step === "subfilter-price" ? (
          <h6 className="m-0 text-center">قیمت کل</h6>
        ) : null}

        {step === "filter" ? (
          <h6
            className="m-0 text-secondary flex-fill"
            onClick={handleRemoveFilters}
          >
            حذف فیلتر ها
          </h6>
        ) : null}
      </Col>

      <Col sm={12} className={"pt-2 overflow-auto " + classes.Main}>
        {step === "filter" ? (
          <Filters subfilter={(subfilter) => setStep(subfilter)} />
        ) : (
          <SubFilters step={step} />
        )}
      </Col>

      <ButtonPrimary
        className={"btn-block " + classes.ActionButton}
        onClick={handleConfirmButtonClick}
      >
        {step === "filter" ? "اعمال" : "تایید"}
      </ButtonPrimary>
    </Row>
  );
};

export default AdsFilter;
