import React, { useState, useRef, useEffect, useContext } from "react";
import { Context } from "../../Context/AdsContext";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SearchIcon from "@material-ui/icons/Search";
import { FiFilter } from "react-icons/fi";
import AdsItem from "./AdsItem/AdsItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import classes from "./AdsList.module.css";
import AdsImage from "../../assets/images/ads-detail-image.png";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));
const AdsList = (props) => {
  const history = useHistory();
  const isInitialMount = useRef(true);
  const adsListDivRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFetchMore, setIsFetchMore] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(true);

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

  const {
    state,
    getAdsList,
    updateStatus,
    getSearchAdsList,
    getFilterAdsList,
    getChosenCategory,
    getHomeBanners,
    getCategoryFilterAdsList,
  } = useContext(Context);
  const progressClasses = useStylesProgress();
  useEffect(() => {
    getChosenCategory();
    getHomeBanners();
    if (state.adsList.length < 1) {
      if (state.filterStatus === "active") {
        getFilterAdsList(state.filters, state.adsListPage);
      } else {
        getAdsList(state.adsListPage);
      }
    } else {
      setLoadingStatus(false);
    }
  }, []);
  console.log(state.adsList)
  const handleChangeSearch = (value) => {
    const searchA = p2e(value.target.value);
    const search = a2e(searchA);

    if (!search) {
      updateStatus("search_ads_query", search);
      setSearchQuery(search);
      setLoadingStatus(true);

      updateStatus("last_ads_list_scroll", 0);
      adsListDivRef.current.scrollTop = 0;
      updateStatus("is_search_ads", false);
      updateStatus("last_ads_list_page", 1);
      updateStatus("update_filter_status", "inactive");
      updateStatus("update_category_filter_status", "inactive");
      getAdsList(state.adsListPage);
      return;
    }
    updateStatus("search_ads_query", search);
    setSearchQuery(search);
  };
  const handleSearchAds = () => {
    updateStatus("last_ads_list_scroll", 0);
    adsListDivRef.current.scrollTop = 0;
    //updateStatus('reset_ads_list',[]);
    setLoadingStatus(true);
    setIsFetchMore(false);
    getSearchAdsList(state.searchQuery, state.searchAdsListPage + 1);
  };
  // console.log(state.adsList);
  const scrollCheck = (event) => {
    updateStatus("last_ads_list_scroll", event.target.scrollTop);
    const size1 = event.target.scrollHeight - event.target.offsetHeight;
    const bottom = size1 - event.target.scrollTop;
    if (bottom <= 1) {
      if (state.filterStatus === "active") {
        if (!isFetchMore && state.isAdsListFetchMore) {
          setIsFetchMore(true);
          getFilterAdsList(state.filters, state.adsListPage + 1);
          return;
        }
      }
      if (state.categoryFilterStatus === "active") {
        if (!isFetchMore && state.isAdsListFetchMore) {
          setIsFetchMore(true);
          getCategoryFilterAdsList(
            state.activeFilterCategory,
            state.adsListPage + 1
          );
          return;
        }
      }
      if (state.isSearchAds) {
        if (!isFetchMore && state.isAdsListFetchMore) {
          setIsFetchMore(true);
          getSearchAdsList(state.searchQuery, state.searchAdsListPage);
          return;
        }
      }
      if (!isFetchMore && state.isAdsListFetchMore) {
        setIsFetchMore(true);
        getAdsList(state.adsListPage + 1);
      }
    }
  };
  // console.log(state.adsListPage);
  const scrollToLastPosition = () => {
    adsListDivRef.current.scrollTop = state.lastAdsListScroll;
  };

  useEffect(() => {
    setIsFetchMore(false);
  }, [state.isAdsListFetchMore]);

  useEffect(() => {
    scrollToLastPosition();
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      setIsFetchMore(false);
      setLoadingStatus(false);
    }
  }, [state.adsList]);

  const handleRemoveFilters = () => {
    setLoadingStatus(true);
    //updateStatus('reset_ads_list',[]);
    updateStatus("last_ads_list_scroll", 0);
    adsListDivRef.current.scrollTop = 0;
    updateStatus("last_ads_list_page", 1);
    updateStatus("update_filter_status", "inactive");
    updateStatus("update_category_filter_status", "inactive");
    getAdsList(state.adsListPage);
  };

  const handleCategoryClick = (categoryId) => {
    updateStatus("last_ads_list_scroll", 0);
    adsListDivRef.current.scrollTop = 0;
    updateStatus("active_filter_category", categoryId);
    setLoadingStatus(true);
    updateStatus("search_ads_query", "");
    updateStatus("last_ads_list_page", 1);
    updateStatus("update_filter_status", "inactive");
    updateStatus("update_category_filter_status", "active");
    getCategoryFilterAdsList(categoryId, state.adsListPage);
  };
  return (
    <Row className="m-0 justify-content-center">
      <Col sm={12} className={classes.Header}>
        <Card className={"mt-3 d-flex flex-row " + classes.SearchBox}>
          <SearchIcon
            className="my-auto"
            color="disabled"
            size={14}
            onClick={handleSearchAds}
          />
          <input
            className={"w-75 py-1 px-1 " + classes.Search}
            type="text"
            value={searchQuery}
            placeholder="جست و جو در تمام خانه ها"
            onChange={handleChangeSearch}
          />
        </Card>

        <div className={"d-flex flex-row text-center mt-3 " + classes.Filters}>
          {state.filterStatus === "active" ||
          state.categoryFilterStatus === "active" ? (
            <p className="w-25 " onClick={handleRemoveFilters}>
              حذف فیلتر ها
            </p>
          ) : (
            <p className="w-25 " onClick={() => history.push("/search")}>
              <FiFilter size={16} /> فیلتر ها
            </p>
          )}
          {state.chosenCategories.map((category, index) => (
            <p
              className="w-25 "
              key={index}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.category.length > 12
                ? category.category.substring(0, 9).concat("...")
                : category.category}
            </p>
          ))}
        </div>
      </Col>

      <Col
        sm={12}
        ref={adsListDivRef}
        className={"pt-2 justify-content-center " + classes.Main}
        onScroll={scrollCheck}
      >
        <Row>
          {loadingStatus ? (
            <div className={"flex-fill text-center pt-3"}>
              <CircularProgress color="primary" classes={progressClasses} />
            </div>
          ) : null}

          {state.adsList.length === 0 && !loadingStatus ? (
            <div className={"flex-fill text-center pt-3"}>
              <h6 className={classes.NoData}>آگهی های مورد نظر یافت نشد</h6>
            </div>
          ) : null}

          {state.adsList.length > 1 ? (
            <Col sm={12} md={4}>
              <Swiper
                pagination={{ dynamicBullets: true, clickable: true }}
                autoplay={{ delay: 1000 }}
                className={"home-ads-pagination " + classes.Swiper}
              >
                {state.homeBanners.map((banner, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={`https://amlaki.vaovan.ir/asset/banner/${banner.picture}`}
                      className="w-100"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          ) : null}

          {state.adsList.map((ads) => (
            <Col sm={12} md={4} key={ads.id.toString()}>
              <AdsItem ads={ads} />
            </Col>
          ))}
          {isFetchMore ? (
            <div className={"flex-fill text-center pt-3 "}>
              <CircularProgress color="primary" classes={progressClasses} />
            </div>
          ) : null}
        </Row>
      </Col>
    </Row>
  );
};

const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default AdsList;
