import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/AuthContext";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useSnackbar } from "notistack";

import Textfield from "../../../components/Ui/Input/Input";

import ReactCodeInput from "react-code-input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";

import Logo from "../../../assets/images/Logo.png";
import FooterImage from "../../../assets/images/auth-footer.png";

import classes from "../VerficationNumber/VerficationNumber.module.css";
import { Footer } from "../../../components/Ui/Footer/Footer";

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));
const VerficationCode = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [authCode, setAuthCode] = useState("");
  const [elementShowStatus, setElementShowStatus] = useState({
    button: "d-block",
    progress: "d-none",
  });
  const { state, checkCode, addCode } = useContext(Context);
  const progressClasses = useStylesProgress();
  const history = useHistory();
  const [timeLeft, setTimeLeft] = useState(60);

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => {
        const time = timeLeft - 1;
        setTimeLeft(time);
      }, 1000);
    }
  }, [timeLeft]);




  
  useEffect(() => {
    console.log(state.checkCodeStatus);
    if (state.checkCodeStatus === "success") {
      // if(state.checkCodeStatus ){
      setElementShowStatus({ button: "d-block", progress: "d-none" });
      history.push("/");
    } else if (state.checkCodeStatus === "codes_dont_match") {
      setElementShowStatus({ button: "d-block", progress: "d-none" });
      enqueueSnackbar("کد وارد شده نادرست است", { variant: "error" });
    } else if (state.checkCodeStatus === "phone_not_exist") {
      history.push("/auth/verfication-info");
    }
  }, [state.checkCodeStatus]);





  const handleChangeAuthCode = (value) => {
    const authCodeA = p2e(value);
    const authCode = a2e(authCodeA);
    setAuthCode(authCode);
  };
  const handleCheckCode = () => {
    setElementShowStatus({ button: "d-none", progress: "d-block" });
    if (authCode.length >= 4) {
      checkCode(state.phoneNumber, authCode);
    } else {
      setElementShowStatus({ button: "d-block", progress: "d-none" });
      enqueueSnackbar("کد ارسالی را وارد کنید", { variant: "error" });
    }
  };

  const handleAddCodeClick = () => {
    addCode(state.phoneNumber);
    setTimeLeft(60);
  };
  return (
    <section className={classes.Section}>
      <Row className={"justify-content-center m-0 " + classes.Logo}>
        <Col sm={12} lg={4} className="p-0 d-block">
          <div className="d-flex flex-column text-center text-white">
            <img src={Logo} className="mx-auto mt-3" width={100} height={100} />
            <h4 className="my-1">واوان هوم</h4>
            <p>خانه ات را پیدا کن</p>
          </div>
        </Col>
      </Row>

      <Row className={"justify-content-center m-0 " + classes.CardSection}>
        <Col sm={12} lg={5} className=" p-0">
          <Card className={"pt-4 " + classes.AuthCard}>
            <div className="w-100 px-4">
              <h5 className="text-right">کد فعالسازی را وارد کنید</h5>
              <p className="text-secondary text-right mt-3">
                کد تایید چهار رقمی به شماره {state.phoneNumber} ارسال شد
              </p>

              <ReactCodeInput
                type="tel"
                fields={4}
                value={authCode}
                inputStyle={{
                  width: "15%",
                  margin: "4px",
                  borderRadius: "8px",
                  fontSize: "30px",
                  textAlign: "center",
                  border: "1px solid #bfbfbf",
                  textTransform: "uppercase",
                  outline: "none",
                }}
                onChange={handleChangeAuthCode}
              />

              {/* <AuthCode
                characters={4}
                onChange={handleChangeAuthCode}
                password
                containerStyle={{
                  direction: "ltr",
                  textAlign: "center",
                }}
                inputStyle={{
                  width: "15%",
                  padding: "3px",
                  borderRadius: "8px",
                  fontSize: "30px",
                  textAlign: "center",
                  marginRight: "5px",
                  marginLeft: "5px",
                  border: "1px solid #bfbfbf",
                  textTransform: "uppercase",
                }}
              /> */}

              <div className={"text-center pt-3 " + elementShowStatus.progress}>
                <CircularProgress color="primary" classes={progressClasses} />
              </div>

              <ButtonPrimary
                className={"btn-block mt-3 " + elementShowStatus.button}
                onClick={() => handleCheckCode()}
              >
                تایید
              </ButtonPrimary>
              <div className="d-flex flex-row mt-4 justify-content-center">
                <p className="text-secondary border-0 m-0">
                  کد فعال سازی را دریافت نکردید
                </p>
                {timeLeft > 0 ? (
                  <h6 className="text-secondary mr-3 pb-2">00:{timeLeft}</h6>
                ) : (
                  <h6
                    className="text-secondary mr-3 pb-2"
                    onClick={handleAddCodeClick}
                  >
                    ارسال مجدد
                  </h6>
                )}
              </div>
            </div>
            <Footer />
          </Card>
        </Col>
      </Row>
    </section>
  );
};

const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
export default VerficationCode;
