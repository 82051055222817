import React,{useState,useContext, useCallback} from "react";
import ClearIcon from '@material-ui/icons/Clear';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory } from "react-router-dom";
import {Context} from '../../Context/AdsContext'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import AdsInfo from '../../components/EditAds/AdsInfo/AdsInfo';
import AdsDescription from '../../components/EditAds/AdsDescription/AdsDescription';

import classes from "./EditAds.module.css";

const EditAds = (props) => {
  const history = useHistory();
  const {state} = useContext(Context);
  const [step,setStep] = useState(1);
  const [ads,setAds] = useState(state.adsDetails)
  const [newAdsInfo,setNewAdsInfo] = useState({isCategoryFirst: true,isSubCategoryFirst: true});

  const handleBackClick = ()=>{
    if(step === 1){
      history.goBack();
    }else{
      setStep(1);
    }
  }
  return (
    <Row className="m-0 p-0 overflow-hidden">
      <Col sm={12} className={"d-flex align-items-center " + classes.Header}>
        {step === 1 ? <ClearIcon size={24} className={classes.BackIcon} onClick={handleBackClick} /> : <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />}
        <p className="m-0 text-white text-center flex-fill">ویرایش آگهی</p>
      </Col>

      <Col sm={12} className={"pt-2 overflow-auto " + classes.Main}>
        {step === 1 ? <AdsInfo isCategoryFirst={newAdsInfo.isCategoryFirst} 
        isSubCategoryFirst={newAdsInfo.isSubCategoryFirst}
        setStep={(value)=> setStep(value)} value={ads} onChange={(info)=>setNewAdsInfo({...newAdsInfo,...info})} /> : null}
        {step === 2 ? <AdsDescription setStep={(value)=> setStep(value)} value={ads} adsInfo={newAdsInfo} /> : null}
      </Col>

    </Row>
  );
};

export default EditAds;
