import React, { useState, useContext, useEffect } from "react";
import Textfield from "../../../components/Ui/Input/Input";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../../../Context/AdsContext";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "react-bootstrap/Card";
import { useSnackbar } from "notistack";
import {addCommas} from '../../../utils/addCommas'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./SubFilter.module.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const SubFilters = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { state, updateStatus,getPriceList,getAreaList } = useContext(Context);
  const [showMinPriceSlide, setShowMinPriceSlide] = useState("AuthProvinceCardClose ");
  const [showMaxPriceSlide, setShowMaxPriceSlide] = useState("AuthProvinceCardClose ");
  
  const [showMinAreaSlide, setShowMinAreaSlide] = useState("AuthProvinceCardClose ");
  const [showMaxAreaSlide, setShowMaxAreaSlide] = useState("AuthProvinceCardClose ");

  const [showBackDrop, setShowBackDrop] = useState(false);
  const backDropClasses = useStyles();

  const handleOnMinPriceClick = (title,price) => {
    updateStatus('update_filters',{minPriceTitle: title,minPrice: price});
    setShowMinPriceSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
  };

  const handleOnMaxPriceClick = (title,price) => {
    updateStatus('update_filters',{maxPriceTitle: title,maxPrice: price});
    setShowMaxPriceSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
  };


  const handleOnMinAreaClick = (area) => {
    updateStatus('update_filters',{minArea: area});
    setShowMinAreaSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
  };
 

  const handleOnMaxAreaClick = (area) => {
    updateStatus('update_filters',{maxArea: area});
    setShowMaxAreaSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
  };

  useEffect(()=>{
    if(props.step === 'subfilter-price'){
      getPriceList();
    }else{
      getAreaList();
    }
  },[])
  if (props.step === "subfilter-price") {
    return (
      <Row className={classes.Row}>
        <Col lg={6} sm={12}>
          <Textfield
            label="قیمت از"
            customSelect
            className="w-100 my-2"
            value={state.filters.minPriceTitle}
            InputProps={{
              readOnly: true,
            }}
            onClick={() => {
              setShowBackDrop(true);
              setShowMinPriceSlide("AuthProvinceCardOpen ");
            }}
          />
        </Col>
        <Col lg={6} sm={12}>
          <Textfield
            label="قیمت تا"
            readOnly
            customSelect
            value={state.filters.maxPriceTitle}
            className="w-100 my-2"
            InputProps={{
              readOnly: true,
            }}
            onClick={() => {
              setShowBackDrop(true);
              setShowMaxPriceSlide("AuthProvinceCardOpen ");
            }}
          />
        </Col>

        <Backdrop
          className={backDropClasses.backdrop}
          open={showBackDrop}
          onClick={() => {
            setShowMinPriceSlide("AuthProvinceCardClose ");
            setShowMaxPriceSlide("AuthProvinceCardClose ");
            setShowBackDrop(!showBackDrop);
          }}
        ></Backdrop>

        <Card className={"pt-4 " + showMinPriceSlide + classes.SlideCard}>
          <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
            قیمت از
          </h5>
          <h6 className="text-center my-2" onClick={()=>handleOnMinPriceClick('','')}>هیچکدام</h6>
          {state.filterPrices.map((price,index) => {
            return <h6 className="text-center my-2" key={index} onClick={()=>handleOnMinPriceClick(getPriceText(price.min),price.min)}>{getPriceText(price.min)}</h6>;
          })}
          
          
        </Card>

        <Card className={"pt-4 " + showMaxPriceSlide + classes.SlideCard}>
          <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
            قیمت تا
          </h5>
          <h6 className="text-center my-2" onClick={()=>handleOnMaxPriceClick('','')}>هیچکدام</h6>
          {state.filterPrices.map((price,index) => {
            return <h6 className="text-center my-2" key={index} onClick={()=>handleOnMaxPriceClick(getPriceText(price.max),price.max)}>{getPriceText(price.max)}</h6>;
          })}
          
        </Card>
      </Row>
    );
  } else {
    return (
      <Row className={classes.Row}>
        <Col lg={6} sm={12}>
          <Textfield
            label="متراژ از"
            customSelect
            className="w-100 my-2"
            value={state.filters.minArea}
            InputProps={{
              readOnly: true,
            }}
            onClick={() => {
              setShowBackDrop(true);
              setShowMinAreaSlide("AuthProvinceCardOpen ");
            }}
          />
        </Col>
        <Col lg={6} sm={12}>
          <Textfield
            label="متراژ تا"
            readOnly
            customSelect
            value={state.filters.maxArea}
            className="w-100 my-2"
            InputProps={{
              readOnly: true,
            }}
            onClick={() => {
              setShowBackDrop(true);
              setShowMaxAreaSlide("AuthProvinceCardOpen ");
            }}
          />
        </Col>

        <Backdrop
          className={backDropClasses.backdrop}
          open={showBackDrop}
          onClick={() => {
            setShowMinPriceSlide("AuthProvinceCardClose ");
            setShowMaxPriceSlide("AuthProvinceCardClose ");
            setShowMinAreaSlide("AuthProvinceCardClose ");
            setShowMaxAreaSlide("AuthProvinceCardClose ");
            setShowBackDrop(!showBackDrop);
          }}
        ></Backdrop>

        <Card className={"pt-4 " + showMinAreaSlide + classes.SlideCard}>
          <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
            متراژ از
          </h5>
          <h6 className="text-center my-2" onClick={()=>handleOnMinAreaClick('','')}>هیچکدام</h6>
          {state.filterAreas.map((area,index) => {
            return <h6 className="text-center my-2" key={index} onClick={()=>handleOnMinAreaClick(area.min)}>{area.min}</h6>;
          })}
          
          
        </Card>

        <Card className={"pt-4 " + showMaxAreaSlide + classes.SlideCard}>
          <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
          متراژ تا
          </h5>
          <h6 className="text-center my-2" onClick={()=>handleOnMaxPriceClick('','')}>هیچکدام</h6>
          {state.filterAreas.map((area,index) => {
            return <h6 className="text-center my-2" key={index} onClick={()=>handleOnMaxAreaClick(area.max)}>{area.max}</h6>;
          })}
          
        </Card>
      </Row>
    );
  }
};
const getPriceText = price =>{
  const newPrice = addCommas(price);
  const priceArray = newPrice.split(",");
  if((priceArray.length-1) === 1){
    return (priceArray[0]+' هزار')
  }
  if((priceArray.length-1) === 2){
    return (priceArray[0]+' میلیون')
  }

  if((priceArray.length-1) === 3){
    return (priceArray[0]+' میلیارد')
  }
}

export default SubFilters;
