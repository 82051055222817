import React,{useState,useContext,useEffect} from "react";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useSnackbar } from 'notistack';
import { Context } from "../../../Context/AuthContext";
import Textfield from "../../../components/Ui/Input/Input";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";

import Logo from "../../../assets/images/Logo.png";
import FooterImage from "../../../assets/images/auth-footer.png";

import classes from "./VerficationInfo.module.css";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));


const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));

const VerficationInfo = () => {
  
  const { enqueueSnackbar } = useSnackbar();
  const [elementShowStatus,setElementShowStatus] = useState({button: 'd-block',progress: 'd-none'})
  const { state,signup,getStates,getCities } = useContext(Context);
  const progressClasses = useStylesProgress();

  const [userInfo,setUserInfo] = useState({name: '',lastName: '',province: '',proviceLabel: '',city: '',cityLabel: ''})
  const [showProvince, setShowProvince] = useState("AuthProvinceCardClose ");
  const [showCity, setShowCity] = useState("AuthCityCardClose ");
  const [showBackDrop,setShowBackDrop] = useState(false);
  const backClasses = useStyles();
  const history = useHistory();

  
  useEffect(() => {
    getStates();
  }, []);

  
  
  useEffect(() => {
    getStates();
  }, []);

  const setCities = (stateId) =>{
    getCities(stateId);
  };

  useEffect(() => {
    if(state.registerStatus === "success"){
      setElementShowStatus({button: 'd-block',progress: 'd-none'})
      history.push("/")
  }else if(state.registerStatus === 'failed'){
    enqueueSnackbar('خطا در ثبت اطلاعات مجددا تلاش فرمایید',{variant: 'error'})
  }else{
    setElementShowStatus({button: 'd-block',progress: 'd-none'})
  }
  }, [state.registerStatus]);
 

  const handleName = (value)=>{
    setUserInfo({...userInfo,name: value.target.value})
  }

  const handleLastName = (value)=>{
    setUserInfo({...userInfo,lastName: value.target.value})
  }

  const handleSignUp = ()=>{
    setElementShowStatus({button: 'd-none',progress: 'd-block'})
    signup(userInfo.name,userInfo.lastName,state.phoneNumber,userInfo.province,userInfo.city);
  }
  return (
    <section className={classes.Section}>
      <Row className={"justify-content-center m-0 " + classes.Logo}>
        <Col sm={12} lg={4} className="p-0 d-block">
          <div className="d-flex flex-column text-center text-white">
            <img src={Logo} className="mx-auto mt-3"  width={50} height={50} />
            <h5 className="my-1">واوان هوم</h5>
            <p>خانه ات را پیدا کن</p>
          </div>
        </Col>
      </Row>

      <Row className={"justify-content-center m-0 " + classes.CardSection}>
        <Col sm={12} lg={5} className=" p-0">
          <Card className={"pt-4 " + classes.AuthCard}>
            <div className="w-100 px-4">
              <h5 className="text-right">اطلاعات خود را وارد کنید</h5>

              <Textfield label="نام" className="w-100 my-3" onChange={handleName} />
              <Textfield label="نام خانوادگی" className="w-100 my-3" onChange={handleLastName}/>
              <div
                onClick={() =>{
                  setShowBackDrop(!showBackDrop)
                  setShowProvince("AuthProvinceCardOpen " )
                }
                }
              >
                <Textfield 
                customSelect
                value={userInfo.proviceLabel}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="استان"
                  className="w-100 my-3"
                />
              </div>

              <div
                onClick={() =>
                  {
                    if(userInfo.province){
                      setShowBackDrop(true);
                      setShowCity("AuthCityCardOpen ")
                    }
                  }
                }
              >
                <Textfield 
                customSelect
                disabled={userInfo.province ? false : true}
                value={userInfo.cityLabel}
                  InputProps={{
                    readOnly: true,
                  }}
                  label="شهر"
                  className="w-100 my-3"
                />
              </div>
              <div className={'text-center pt-3 '+elementShowStatus.progress}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div>
              <ButtonPrimary className={"btn-block my-3 "+elementShowStatus.button} onClick={()=> handleSignUp()}>
                ورود به برنامه
              </ButtonPrimary>
            </div>
          </Card>
        </Col>

        <Backdrop
          className={backClasses.backdrop}
          open={showBackDrop}
          onClick={() =>
            {
            setShowCity("AuthCityCardClose " )
            setShowProvince("AuthProvinceCardClose ")
            setShowBackDrop(!showBackDrop);
          }
          }
        >
        </Backdrop>

        <Card className={"pt-4 " + showProvince + classes.AuthCityCard}>
          {state.states.map(item =>{
            return <div onClick={()=>{
              setShowProvince("AuthProvinceCardClose " );
              setShowBackDrop(!showBackDrop);
              setUserInfo({...userInfo,province: item.id,proviceLabel: item.state})
              setCities(item.id)
            }}><h6 className='text-center my-2' style={{cursor: 'pointer'}}>{item.state}</h6></div>
          })}
          </Card>

          <Card className={"pt-4 " + showCity + classes.AuthCityCard}>
          {state.cities.map(item =>{
            return <div onClick={()=>{
              setShowCity("AuthCityCardClose " );
              setShowBackDrop(!showBackDrop);
              setUserInfo({...userInfo,city: item.id,cityLabel: item.city})
            }}><h6 className='text-center my-2' style={{cursor: 'pointer'}}>{item.city}</h6></div>
          })}
          </Card>

        
      </Row>
   
    </section>
  );
};



export default VerficationInfo;
