import React,{useState,useEffect,useContext,useRef} from "react";
import {Context} from '../../../../Context/AuthContext';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import classes from "./InboxDetail.module.css";



const useStylesProgress = makeStyles((theme) => ({
    colorPrimary: {
      color: '#2979FF'
    },
  }));

const InboxDetail = (props) => {
  const history = useHistory();
  const messagesDivRef = useRef(null)
  const {state,getTicketDetail,updateTicketDetails,replyTicket,updateReplyTicketStatus} = useContext(Context);
  const [message,setMessage] = useState('');
  const progressClasses = useStylesProgress();

  useEffect(()=>{
    getTicketDetail(state.activeTicket);
    return ()=>{
        updateTicketDetails([]);
    }
  },[])

  useEffect(()=>{
    //setElementShowStatus({button: 'd-block',progress: 'd-none'})
    if(state.addTicketReplyStatus === 'success'){
    updateReplyTicketStatus('');
    setMessage('');
    }
  },[state.addTicketReplyStatus])

  useEffect(()=>{
    scrollToBottom();
  },[state.ticketDetails])

  const handleBackClick = ()=>{
    history.goBack();
}


const handleChangeMessage = (value)=>{
    const messageA = p2e(value.target.value);
    const message = a2e(messageA);
    setMessage(message);
  }

  const handleSendClick = ()=>{
    //setElementShowStatus({button: 'd-none',progress: 'd-block'})
    if(message){
        replyTicket(state.activeTicket,message);
    }
  }

  const scrollToBottom = () => {
    messagesDivRef.current.scrollTop = messagesDivRef.current.offsetHeight;
  }
  return (
    <div className={classes.Container}>
    <Row className={"m-0 "+classes.HeaderSection}>
      <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
      <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />
      <p className='m-0 text-white text-center flex-fill'>واوان</p>
      </Col>

      <Col sm={12} md={4} className={classes.Main} ref={messagesDivRef}>
    {state.ticketDetails.length < 1 ? <div className={'flex-fill text-center pt-3'}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div> : null}

    <Card className={classes.messageContainer}>
    {state.ticketDetails.map( (ticket,index) => {
        if(ticket.sender === localStorage.getItem('id')){
            return (<div className={"d-flex mt-2 "} key={index.toString()}>
              <p className={classes.RightMessage}>{ticket.message}</p>
            
        </div>)
        }else{
            return (<div className={"d-flex mt-2 "+classes.LeftMessage} key={index.toString()}>
              <p className={classes.RightMessage}>{ticket.message}</p>
        </div>) 
        }
    }
          )}
        </Card>
        </Col>
    
        <Col sm={12} md={4} className={classes.Footer}>
        <textarea placeholder='متن پیام ...' value={message} onChange={handleChangeMessage}></textarea>
        <button className='bg-primary' onClick={handleSendClick}>ارسال</button>
    </Col>
    </Row>
    </div>
  );
};

const p2e = s => s.replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d))
const a2e = s => s.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
export default InboxDetail;
