import React from "react";
import ButtonPrimary from '../../components/Ui/Button/ButtonPrimary'
import classes from "./InstallBanner.module.css";

const InstallBanner = (props) => {

    const handleKhownClick = ()=>{
        props.continue();
    }
  return (
    <div className={classes.Main}>
        <div className={classes.Body}>
      <h6>
       وب اپلیکیشن واوان ! را به صفحه اصلی موبایل خود اضافه کنید
      </h6>
      <div className="d-flex ">
        <i className="icon-0-2-29 icon-d3-0-2-48 ">
          <svg
            width="22"
            height="22"
            color="#333"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#007AFF" fillRule="nonzero">
              <path d="M11.965 15.709a.662.662 0 00.658-.651V4.73l-.045-1.438.787.825 1.596 1.665a.617.617 0 00.454.204c.34 0 .606-.25.606-.59a.592.592 0 00-.197-.439l-3.382-3.291c-.167-.167-.31-.22-.477-.22-.174 0-.318.053-.484.22L8.09 4.957c-.12.129-.197.265-.197.439 0 .34.258.59.606.59.159 0 .34-.068.461-.204l1.59-1.665.801-.825L11.3 4.73v10.328c0 .356.303.65.666.65z"></path>
              <path d="M17.572 23.275c1.96 0 2.943-.991 2.943-2.92v-9.89c0-1.93-.984-2.913-2.943-2.913H14.81v1.34h2.731c1.037 0 1.635.56 1.635 1.641v9.753c0 1.082-.598 1.642-1.635 1.642H6.374c-1.06 0-1.635-.56-1.635-1.642v-9.753c0-1.082.575-1.642 1.635-1.642h2.739V7.552h-2.77c-1.952 0-2.943.976-2.943 2.913v9.89c0 1.936.991 2.92 2.943 2.92h11.229z"></path>
            </g>
          </svg>
        </i>
        <p>
          1 - در نوار پایین روی دکمه
          <span className={classes.IphoneText}>Share</span>
          بزنید.
        </p>
      </div>
      <div className="d-flex ">
        <i className="icon-0-2-29 icon-d4-0-2-49 ">
          <svg
            width="22"
            height="22"
            color="#333"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M16.893.92c2.253 0 3.955.237 5.185 1.457 1.22 1.21 1.436 2.902 1.436 5.164V17.3c0 2.263-.216 3.945-1.436 5.165-1.22 1.22-2.932 1.456-5.185 1.456H7.056c-2.253 0-3.955-.236-5.184-1.456C.652 21.255.435 19.563.435 17.3V7.472c0-2.184.227-3.876 1.446-5.086C3.101 1.166 4.813.921 6.987.921zm-4.938 5.274c-.56 0-.885.423-.885 1.023v4.279H6.81c-.63 0-1.033.344-1.033.925 0 .56.433.895 1.033.895h4.26v4.092c0 .6.324 1.014.885 1.014.58 0 .925-.394.925-1.014v-4.092h4.279c.59 0 1.013-.335 1.013-.895 0-.58-.393-.925-1.013-.925h-4.28v-4.28c0-.62-.344-1.022-.924-1.022z"
            ></path>
          </svg>
        </i>
        <p>
          2 - در منوی بازشده در قسمت پایین گزینه
          <span className={classes.IphoneText}>Add to Home Screen</span> را انتخاب کنید.
        </p>
      </div>
      <div className="d-flex ">
        <i className="icon-0-2-29 icon-d5-0-2-50 ">
          <svg
            width="22"
            height="26"
            color="#333"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none" fillRule="evenodd">
              <path d="M0 0h24v24H0z"></path>
              <path
                d="M2.19 16.875l.911-2.708h3.612l.912 2.708h1.19l-3.35-9.463H4.348L1 16.875h1.19zm4.19-3.711H3.434L4.857 8.94h.1l1.422 4.224zM12.09 17c.938 0 1.743-.466 2.17-1.252h.101v1.127h1.033V7h-1.083v3.92h-.095c-.383-.773-1.182-1.239-2.126-1.239-1.724 0-2.85 1.443-2.85 3.66 0 2.223 1.114 3.659 2.85 3.659zm.252-1.017c-1.24 0-1.982-.99-1.982-2.642 0-1.646.749-2.643 1.982-2.643 1.226 0 1.994 1.023 1.994 2.643 0 1.633-.76 2.642-1.994 2.642zM19.697 17c.937 0 1.743-.466 2.17-1.252h.101v1.127H23V7h-1.082v3.92h-.094c-.385-.773-1.183-1.239-2.127-1.239-1.724 0-2.85 1.443-2.85 3.66 0 2.223 1.114 3.659 2.85 3.659zm.251-1.017c-1.239 0-1.982-.99-1.982-2.642 0-1.646.75-2.643 1.982-2.643 1.228 0 1.995 1.023 1.995 2.643 0 1.633-.761 2.642-1.994 2.642z"
                fill="#007AFF"
                fillRule="nonzero"
              ></path>
            </g>
          </svg>
        </i>
        <p>
          3 - در مرحله بعد در قسمت بالا روی
          <span className={classes.IphoneText}>Add</span>
          بزنید.
        </p>
      </div>
      <ButtonPrimary className='w-75 mt-4' onClick={handleKhownClick}>
          متوجه شدم
      </ButtonPrimary>
      </div>
    </div>
  );
};

export default InstallBanner;
