import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/AuthContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Avatar from "../../assets/images/avatar.png";

import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { FiUser } from "react-icons/fi";
import { FiAlertCircle } from "react-icons/fi";
import { FiPhoneCall } from "react-icons/fi";

import ButtonPrimary from "../../components/Ui/Button/ButtonPrimary";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";

import classes from "./Profile.module.css";
// import checkLogin from "../../hooks/chechLogin";
import checkLogin from "../../hooks/chechLogIn";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = (props) => {
  const history = useHistory();
  const [singoutDialogStatus, setsingoutDialogStatus] = useState(false);

  const { state, signout, getUser } = useContext(Context);
  useEffect(() => {
    getUser();
  }, []);

  const handleOpenSingoutDialog = () => {
    setsingoutDialogStatus(true);
  };

  const handleCloseSingoutDialog = () => {
    setsingoutDialogStatus(false);
  };

  const handleSignoutClick = () => {
    signout();
    history.go("/auth/verfication-number");
  };
  const firstName = localStorage.getItem("firstname");
  const lastName = localStorage.getItem("lastname");
  return (
    <Row className="m-0 justify-content-center overflow-auto">
      <Col sm={12} className={classes.Header}>
        <h6 className="text-right text-white">حساب کاربری</h6>
      </Col>

      <Col sm={12} className={classes.Main}>
        <Card className={classes.MainNavigation}>
          {state.userProfile.profile_picture ? (
            <img
              src={`https://amlaki.vaovan.ir/asset/user_profile/${state.userProfile.profile_picture}`}
              className="rounded-circle"
            />
          ) : (
            <img src={Avatar} className="rounded-circle" />
          )}
          <h6
            className={"text-center font-weight-bold mb-2 " + classes.UserName}
          >{` ${
            firstName && lastName
              ? firstName.concat(` ${lastName}`)
              : "کاربر مهمان"
          }`}</h6>
          <h6 className="text-center text-secondary border-bottom pb-2">
            {localStorage.getItem("phone")}
          </h6>
          {checkLogin() && (
            <div
              className={"d-flex flex-row px-3 my-3 " + classes.Navigation}
              onClick={() => {
                localStorage.getItem("id")
                  ? history.push("/my-ads")
                  : history.push("/auth/verfication-number");
              }}
            >
              <FormatListBulletedIcon className="ml-2" size={8} />
              <p className="m-0 flex-fill text-right">آگهی های من</p>
              <i className={classes.Arrow}></i>
            </div>
          )}
          {checkLogin() && (
            <div
              className={"d-flex flex-row px-3 my-3 " + classes.Navigation}
              onClick={() => {
                localStorage.getItem("id")
                  ? history.push("/MySubset")
                  : history.push("/auth/verfication-number");
              }}
            >
              <FormatListBulletedIcon className="ml-2" size={8} />
              <p className="m-0 flex-fill text-right">زیر مجموعه های من</p>
              <i className={classes.Arrow}></i>
            </div>
          )}
          {checkLogin() && (
            <div
              className={"d-flex flex-row px-3 my-3 " + classes.Navigation}
              onClick={() => {
                localStorage.getItem("id")
                  ? history.push("/profile/edit")
                  : history.push("/auth/verfication-number");
              }}
            >
              <FiUser className="ml-2" size={20} />
              <p className="m-0 flex-fill text-right">ویرایش پروفایل</p>
              <i className={classes.Arrow}></i>
            </div>
          )}

          <div
            className={"d-flex flex-row px-3 my-3 " + classes.Navigation}
            onClick={() => history.push("/aboutus")}
          >
            <FiAlertCircle className="ml-2" size={20} />
            <p className="m-0 flex-fill text-right">درباره اپلیکیشن</p>
            <i className={classes.Arrow}></i>
          </div>
          <div
            className={"d-flex flex-row px-3 my-3 " + classes.Navigation}
            onClick={() => {
              window.open("tel:+09174024788");
            }}
          >
            <FiPhoneCall className="ml-2" size={20} />
            <p className="m-0 flex-fill text-right">تماس با ما</p>
            <i className={classes.Arrow}></i>
          </div>
          {localStorage.getItem("id") ? (
            <h6
              className={"text-center m-0 mt-2 " + classes.Logout}
              onClick={handleOpenSingoutDialog}
            >
              خروج از حساب کاربری
            </h6>
          ) : (
            <h6
              className={"text-center m-0 mt-2 " + classes.Logout}
              onClick={() => history.push("/auth/verfication-number")}
            >
              ورود به حساب کاربری
            </h6>
          )}
        </Card>
      </Col>

      <Dialog
        open={singoutDialogStatus}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSingoutDialog}
      >
        <h6 className="px-4 py-3 text-right">آیا از خروج اطمینان دارید ؟</h6>
        <DialogActions>
          <ButtonPrimary onClick={handleSignoutClick} className="w-25">
            بله
          </ButtonPrimary>
          <button
            onClick={handleCloseSingoutDialog}
            className={"w-25 " + classes.DeleteButton}
          >
            خیر
          </button>
        </DialogActions>
      </Dialog>
    </Row>
  );
};

export default React.memo(Profile);
