import React from "react";
import SwiperComponent from "./Counseling-swiper/CounselingSwiper";
import CounselingProducts from "./Counseling-products/Counseling-products";
import "./Counseling.css";

const Counseling = (props) => {
  return (
    <>
      <SwiperComponent />
      <CounselingProducts />
    </>
  );
};

export default Counseling;

// http://vaovan.ir/amlaki/index.php/Consultant/all
