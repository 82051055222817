import React, { useState } from "react";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import classes from "./AboutUs.module.css";

const AboutUs = (props) => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };
  return (
    <Row className="m-0">
      <Col sm={12} className={"d-flex align-items-center " + classes.Header}>
        <ArrowForwardIcon
          size={24}
          className={classes.BackIcon}
          onClick={handleBackClick}
        />
        <p className="m-0 text-white text-center flex-fill">درباره ما</p>
      </Col>

      <Col sm={12} className={classes.Main}>
        <h6 className="m-4">
          سلام و خوش امد گویی خدمت شما دوستان عزیز لازم به ذکر است هرگونه فعالیت
          توسط مشاور املاکی در این اپ غیر مجاز و پیگرد قانونی دارد این اپ به شما
          کمک میکند که ملک دلخواه خودتون رو خریداری،فروش،رهن و ..... نمایید شما
          در این اپ ملک مورد نظر خود را به نمایش میزارید و مراجعه کننده ان را
          تیک میزند شرکت با هر دو طرف هماهنگ میکند و قرار بازدید میگذارد و ...
          بابت خدمات انجام شده شرکت کمیسیون دریافت میکند تمامی قیمت ها توسط
          کارشناسان کارکشته ی شرکت کارشناسی شده قصد ما فروش ملک نیست قصد ما
          ساختن بهترین خاطره و براورده کردن ارزوی شماست زمان ورود شما در قسمت
          پروفایل یک کد اختصاصی بهتون داده میشود که شما میتونید با این کد
          بازاریابی کنید برای دریافت اطلاعات بیشتر در این زمینه با ما تماس
          بگیرید این شرکت حتی بابت این ک شما درامد باورنکردنیی داشته باشید هم
          فعالیت هایی انجام داده و راه های درامدزایی مختلفی طبق سرمایه ی شما
          بهتون پیشنهاد میده در نظر داشته باشید ک حتی بدون هیچ گونه سرمایه ای هم
          میتونید درامد کسب کنید برای اطلاعات بیشتر با ما تماس بگیرید
        </h6>
      </Col>
    </Row>
  );
};

export default AboutUs;
