import React, { useState, useContext, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Context } from "../../Context/AdsContext";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";
import { FiMapPin } from "react-icons/fi";
import { AiOutlineRightCircle } from "react-icons/ai";
import CommentIcon from "@material-ui/icons/Comment";
import AdsImage from "../../assets/images/ads-detail-image.png";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import CheckIcon from "@material-ui/icons/Check";
import ButtonPrimary from "../../components/Ui/Button/ButtonPrimary";
import { addCommas } from "../../utils/addCommas";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";

import Card from "react-bootstrap/Card";

import classes from "./Ads.module.css";
import checkLogin from "../../hooks/chechLogIn";

const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: "#2979FF",
  },
}));

const CallIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.578"
    height="27.627"
    viewBox="0 0 27.578 27.627"
  >
    <path
      id="Icon_feather-phone-call"
      data-name="Icon feather-phone-call"
      d="M18.945,6.378a6.1,6.1,0,0,1,4.817,4.817M18.945,1.5a10.975,10.975,0,0,1,9.694,9.682M27.42,20.913v3.658a2.439,2.439,0,0,1-2.658,2.439,24.132,24.132,0,0,1-10.524-3.744A23.779,23.779,0,0,1,6.921,15.95,24.132,24.132,0,0,1,3.178,5.378,2.439,2.439,0,0,1,5.6,2.719H9.263a2.439,2.439,0,0,1,2.439,2.1,15.657,15.657,0,0,0,.854,3.427,2.439,2.439,0,0,1-.549,2.573l-1.549,1.549a19.511,19.511,0,0,0,7.317,7.317l1.549-1.549a2.439,2.439,0,0,1,2.573-.549,15.657,15.657,0,0,0,3.427.854A2.439,2.439,0,0,1,27.42,20.913Z"
      transform="translate(-2.167 -0.396)"
      fill="none"
      stroke="#448aff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
);

const Ads = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const {
    state,
    getAdsDetails,
    updateStatus,
    addFavoriteAds,
    deleteFavoriteAds,
    updateAdsFavoriteStatus,
    addVisitRequest,
  } = useContext(Context);
  const [elementShowStatus, setElementShowStatus] = useState({
    button: "d-block",
    progress: "d-none",
  });

  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const { adsId } = useParams();
  const progressClasses = useStylesProgress();

  SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

  useEffect(() => {
    getAdsDetails(adsId);
    return () => {
      const newAds = {
        details: {
          id: "",
          user_id: "",
          category_id: "",
          sub_category_id: "",
          title: "",
          address: "",
          area: "",
          price: "",
          about: "",
          is_like: "",
          base_options: [],
        },
        pictures: [],
        option_and_attributes: [],
      };
      updateStatus("get_ads_details", newAds);
    };
  }, []);

  useEffect(() => {
    if (state.addFavoriteAdsStatus === "success") {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 1;
        updateStatus("get_ads_details", { ...adsDetail });
      }
    } else {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 0;
        updateStatus("get_ads_details", { ...adsDetail });
      }
    }

    if (state.deleteFavoriteAdsStatus === "success") {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 0;
        updateStatus("get_ads_details", { ...adsDetail });
      }
    } else {
      if (state.lastFavoriteAdsId === state.adsDetails.details.id) {
        let adsDetail = { ...state.adsDetails };
        adsDetail.details["is_like"] = 1;
        updateStatus("get_ads_details", { ...adsDetail });
      }
    }

    // if(state.addFavoriteAdsStatus === 'success'){
    //   if(state.lastFavoriteAdsId === state.adsDetails.details.id){
    //     let adsDetail = {...state.adsDetails};
    //     adsDetail.details['is_like'] = 1;
    //     updateStatus('get_ads_details',{...adsDetail});

    //   }
    // }

    // if(state.deleteFavoriteAdsStatus === 'success'){
    //   if(state.lastFavoriteAdsId === state.adsDetails.details.id){
    //     let adsDetail = {...state.adsDetails};
    //     adsDetail.details['is_like'] = 0;
    //     updateStatus('get_ads_details',{...adsDetail});
    //   }
    // }
  }, [state.addFavoriteAdsStatus, state.deleteFavoriteAdsStatus]);

  const handleImageClick = (imagePath) => {
    setSelectedImage(imagePath);
    setShowImageModal(true);
  };

  const handleCloseModal = () => {
    setShowImageModal(false);
    setSelectedImage("");
  };

  const handleFavoriteClick = () => {
    if (state.adsDetails.details.is_like === 0) {
      let adsDetail = { ...state.adsDetails };
      adsDetail.details["is_like"] = 1;
      updateStatus("get_ads_details", { ...adsDetail });
      addFavoriteAds(state.adsDetails.details.id);
    } else {
      let adsDetail = { ...state.adsDetails };
      adsDetail.details["is_like"] = 0;
      updateStatus("get_ads_details", { ...adsDetail });
      deleteFavoriteAds(state.adsDetails.details.id);
    }
  };

  const handleVisitingRequestClick = () => {
    setElementShowStatus({ button: "d-none", progress: "d-block" });
    addVisitRequest(state.adsDetails.details.id);
  };

  useEffect(() => {
    setElementShowStatus({ button: "d-block", progress: "d-none" });
    if (state.addVisitRequestStatus === "success") {
      enqueueSnackbar("درخواست شما با موفقیت ثبت شد", { variant: "success" });
      updateStatus("add_visit_request", "");
    } else if (state.addVisitRequestStatus === "failed") {
      enqueueSnackbar("خطا در ثبت درخواست مجددا تلاش فرمایید", {
        variant: "error",
      });
      updateStatus("add_visit_request", "");
    }
  }, [state.addVisitRequestStatus]);

  function handelImages(e) {
    console.log(e.target.src);

    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          zIndex: "100",
          backgroundColor: "red",
          top: "0",
          right: "0",
        }}
      >
        hello
      </div>
    );
  }

  if (state.adsDetails.details.id) {
    return (
      <section className={classes.Section}>
        <AiOutlineRightCircle
          size={32}
          className={classes.BackIcon}
          onClick={() => history.goBack()}
        />
        <Swiper
          pagination={{ dynamicBullets: true, clickable: true }}
          autoplay={{ delay: 1000 }}
          className={"ads-pagination " + classes.Swiper}
        >
          {state.adsDetails.pictures.length > 0 ? (
            state.adsDetails.pictures.map((picture, index) => (
              <SwiperSlide key={index}>
                <img
                  src={`https://amlaki.vaovan.ir/asset/pictures/${picture.picture}`}
                  className="w-100"
                  onError={(e) => console.error("Image loading error:", e)}
                  onClick={() =>
                    handleImageClick(
                      `https://amlaki.vaovan.ir/asset/pictures/${picture.picture}`
                    )
                  }
                />
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <img src={AdsImage} />
            </SwiperSlide>
          )}
        </Swiper>

        {showImageModal && (
          <div
            className="image-modal"
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "500",
            }}
          >
            <div
              className="modal-content"
              style={{
                position: "relative",
              }}
            >
              <img
                src={selectedImage}
                alt="Full Size"
                className="full-size-image"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
              <button onClick={handleCloseModal}>بستن</button>
            </div>
          </div>
        )}

        <Card className={classes.Main}>
          <Card.Body className={classes.MainBody}>
            <div className="d-flex flex-row">
              <h6 className="font-weight-bold">
                {state.adsDetails.details.title}
              </h6>
              <h6 className="flex-fill text-left font-weight-bold">
                {state.adsDetails.details.price !== " "
                  ? addCommas(`تومان :${state.adsDetails.details.price}`)
                  : "قیمت وجود ندارد"}
              </h6>
            </div>
            <p className={"text-right m-0 mt-2 " + classes.Location}>
              <FiMapPin size={12} className="ml-1" />
              {state.adsDetails.details.address}
            </p>
            <div className={"mt-2 " + classes.Time}>
              <p className="d-inline-block">
                {timeDifference(state.adsDetails.details.start_at)}
              </p>
              {checkLogin() &&
                (state.adsDetails.details.is_like === 1 ? (
                  <FavoriteIcon
                    size={13}
                    onClick={handleFavoriteClick}
                    color="secondary"
                  />
                ) : (
                  <FavoriteBorderIcon
                    size={13}
                    onClick={handleFavoriteClick}
                    color="secondary"
                  />
                ))}
            </div>

            <div
              className={
                "w-100 mt-3 py-2 d-flex flex-row justify-content-center " +
                classes.HeaderInfo
              }
            >
              {state.adsDetails?.option_and_attributes_define.map((item) => {
                console.log(item);
                return (
                  <p className="m-0">
                    {item.name ? item.name : null}:{" "}
                    {item.attribute_bool
                      ? item.attribute_bool
                      : item.attribute_int
                      ? item.attribute_int
                      : item.attribute_string
                      ? item.attribute_string
                      : null}{" "}
                    |{" "}
                  </p>
                );
              })}
            </div>
            <h6 className="text-right mt-4 font-weight-bold">دیگر مشخصات</h6>
            {state.adsDetails.option_and_attributes.map((option) => {
              return (
                <div
                  className={
                    "d-flex flex-row border-bottom pb-2 mt-3 mb-3 " +
                    classes.MainInfo
                  }
                >
                  <p className="m-0 flex-fill text-right">{option.name}</p>
                  <p className="m-0 flex-fill">
                    {option.attribute_int
                      ? option.attribute_int
                      : option.attribute_bool
                      ? option.attribute_bool
                      : option.attribute_string}
                  </p>
                </div>
              );
            })}
            {/*          

          <h6 className='text-right mt-3 font-weight-bold'>امکانات</h6>
          <div className={'justify-content-start border-bottom pb-2 '+classes.Details}>
            {state.adsDetails.option_and_attributes.map(option=>{
              if(option.option_id === '1'){
                return(<p className='d-inline-block w-50 m-0 mb-1'>
                <CheckIcon fontSize='small' className="ml-1 mb-1"/>
                {option.name+' '+option.attribute_bool}
                </p>);
              }else if(option.option_id === '2'){
                return(<p className='d-inline-block w-50 m-0 mb-1'>
                <CheckIcon fontSize='small' className="ml-1 mb-1"/>
                {option.name+' '+option.attribute_string}
                </p>);
              }else{
                return(<p className='d-inline-block w-50 m-0 mb-1'>
                <CheckIcon fontSize='small' className="ml-1 mb-1"/>
                {option.name+' '+option.attribute_int}
                </p>);
              }
              
            })}
              

              
              </div> */}

            <h6 className="text-right mt-3 font-weight-bold">توضیحات</h6>
            <p className={"mt-2 text-right " + classes.Location}>
              {state.adsDetails.details.about}
            </p>
          </Card.Body>
          {checkLogin() && (
            <p
              className={"text-right text-white " + classes.CallToSupport}
              onClick={() => history.push("/ticket")}
            >
              <CommentIcon
                className="ml-1"
                style={{ fill: "white" }}
                size={8}
              />
              گفتگو با پشتیبانی
            </p>
          )}

          <div
            className={
              "d-flex flex-row justify-content-center " + classes.RequestAction
            }
          >
            <div
              className={"flex-fill text-center " + elementShowStatus.progress}
            >
              <CircularProgress color="primary" classes={progressClasses} />
            </div>
            <a
              href={`tel:${state.adsDetails.details.user_phone[0].phone}`}
              className="w-100"
            >
              <ButtonPrimary
                className={
                  "w-100 " +
                  classes.RequestButton +
                  " " +
                  elementShowStatus.button
                }
                // onClick={handleVisitingRequestClick}
              >
                تماس
                {CallIcon}
              </ButtonPrimary>
            </a>
          </div>
        </Card>
      </section>
    );
  } else {
    return (
      <section className={classes.Section}>
        <div className={"flex-fill text-center pt-3 "}>
          <CircularProgress color="primary" classes={progressClasses} />
        </div>
      </section>
    );
  }
};

function timeDifference(current) {
  const date_test = new Date(current.replace(/-/g, "/"));
  const d = new Date();

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = d.getTime() - date_test.getTime();

  if (elapsed < msPerMinute) {
    return " لحظاتی پیش";
  } else if (elapsed < msPerHour) {
    if (elapsed < 900000) {
      return " یک ربع پیش";
    } else if (elapsed < 1200000) {
      return " نیم ساعت پیش";
    } else {
      return " 1 ساعت پیش";
    }
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " ساعت پیش";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " روز پیش";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " ماه پیش";
  } else {
    return Math.round(elapsed / msPerYear) + " سال پیش";
  }
}

export default Ads;
