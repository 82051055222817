import React,{useState,useEffect,useContext} from "react";
import {Context} from '../../../Context/AdsContext'
import { useHistory } from "react-router-dom";
import { addCommas } from "../../../utils/addCommas";

import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import AdsImage from "../../../assets/images/ads-image.png";
import { FiMapPin } from "react-icons/fi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';

import classes from "./FavoriteItem.module.css";

const AdsItem = (props) => {
  const {state,updateStatus,addFavoriteAds,deleteFavoriteAds,updateAdsFavoriteStatus} = useContext(Context);
  const [favorite,setFavorite] = useState(true);
  const history = useHistory();

  useEffect(()=>{
    if(state.addFavoriteAdsStatus === 'success'){
      if(state.lastFavoriteAdsId === props.ads.id){
        updateAdsFavoriteStatus(state.adsList,props.ads.id,1);
        setFavorite(true);
        updateStatus('add_favorite_ads','');
        updateStatus('add_last_favorite_id','');
      }
    }else{
      if(state.lastFavoriteAdsId === props.ads.id){
        updateAdsFavoriteStatus(state.adsList,props.ads.id,0);
        setFavorite(false);
        updateStatus('add_favorite_ads','');
        updateStatus('add_last_favorite_id','');
      }
    } 

    if(state.deleteFavoriteAdsStatus === 'success'){
      if(state.lastFavoriteAdsId === props.ads.id){
        updateAdsFavoriteStatus(state.adsList,props.ads.id,0);
        setFavorite(false);
        updateStatus('delete_favorite_ads','');
        updateStatus('add_last_favorite_id','');
      }
    }else{
      if(state.lastFavoriteAdsId === props.ads.id){
        updateAdsFavoriteStatus(state.adsList,props.ads.id,1);
        setFavorite(true);
        updateStatus('delete_favorite_ads','');
        updateStatus('add_last_favorite_id','');
      }
    }
  },[state.addFavoriteAdsStatus,state.deleteFavoriteAdsStatus])


  const handleFavoriteClick = ()=>{
    if(favorite){
      deleteFavoriteAds(props.ads.id);
      setFavorite(false);
    }else {
      addFavoriteAds(props.ads.id);
      setFavorite(true);
    }
  }

  return (
    <Card className={"d-flex flex-row mt-2 " + classes.Container} >
      <div className='d-flex' style={{width: '90%'}} onClick={()=>history.push("/ads/"+props.ads.id)}>
      <div className="position-relative py-2 mr-2">
      {props.ads.pictures.length > 0 ? 
        <img src={`https://amlaki.vaovan.ir/asset/pictures/${props.ads.pictures[0].picture}`} className="rounded" width={100} height={100}/>
        :
        <img src={AdsImage} className="rounded" width={100} height={100}/>
      }
        <div className={classes.AdsBadge}>
        <p>فروش</p>

        </div>
      </div>

      <div className={"w-75 pr-3 position-relative "+classes.AdsInfo}>
          <h6 className='text-right mt-2'>{props.ads.title}</h6>
          <p className='text-right m-0'>
              <FiMapPin size={10} className="ml-1"/>
              {props.ads.address}
          </p>

          {/* <div className="d-flex flex-row mt-2">
              <p className='m-0 ml-1'>سال ساخت : {1392}</p> 
              <p className='m-0 mr-2'>تعداد خواب : 2</p> 
          </div> */}
          <div className="d-flex flex-row mt-2">
              <p className='m-0 ml-1'>قیمت : </p> 
              <p className='m-0 font-weight-bold'>{addCommas(props.ads.price)} تومان</p> 
          </div>
          <div className="d-flex flex-row mt-1 mb-1">
              <p className='m-0 ml-1'>ودیعه : </p> 
              <p className='m-0 font-weight-bold'>150,000 تومان</p> 
          </div>

          </div>
          </div>
          <div className={classes.FavoriteIcon} onClick={handleFavoriteClick}>
          {favorite ? <FavoriteIcon size={13}  color='secondary'/> : <FavoriteBorderIcon size={13} color='secondary'/>}
              
          </div>
          <p className={classes.AdsDate}>{timeDifference(props.ads.start_at)}</p>
      
    </Card>
  );
};


function timeDifference(current) {
  const date_test = new Date(current.replace(/-/g,"/"));
  const d = new Date();

  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = d.getTime() - date_test.getTime();

  if (elapsed < msPerMinute) {
       return ' لحظاتی پیش';   
  }

  else if (elapsed < msPerHour) {
    if(elapsed < 900000){
      return ' یک ربع پیش';   
    }else if(elapsed < 1200000){
      return ' نیم ساعت پیش';   
    }else {
      return ' 1 ساعت پیش';   
    }
  }

  else if (elapsed < msPerDay ) {
       return Math.round(elapsed/msPerHour ) + ' ساعت پیش';   
  }

  else if (elapsed < msPerMonth) {
      return Math.round(elapsed/msPerDay) + ' روز پیش';   
  }

  else if (elapsed < msPerYear) {
      return Math.round(elapsed/msPerMonth) + ' ماه پیش';   
  }

  else {
      return Math.round(elapsed/msPerYear ) + ' سال پیش';   
  }
}
export default AdsItem;
