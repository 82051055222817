import React, { useState, useContext, useEffect } from "react";
import Textfield from "../../../components/Ui/Input/Input";
import { makeStyles } from "@material-ui/core/styles";
import { Context } from "../../../Context/AdsContext";
import ButtonPrimary from "../../../components/Ui/Button/ButtonPrimary";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "react-bootstrap/Card";
import { useSnackbar } from "notistack";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classes from "./Filters.module.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const Filters = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const { state, getCategory, getHouseType, getOptions, updateStatus } =
    useContext(Context);
  const [showAdsTypeSlide, setShowAdsTypeSlide] = useState(
    "AuthProvinceCardClose "
  );
  const [showTypeHouseSlide, setShowTypeHouseSlide] = useState(
    "AuthProvinceCardClose "
  );
  const [showSlide, setShowSlide] = useState("AuthProvinceCardClose ");
  const [activeOption, setActiveOption] = useState(0);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const backDropClasses = useStyles();

  useEffect(() => {
    getCategory();
  }, []);

  const handleCategoryOnClick = (category, categoryId) => {
    let filters = { category: category, categoryId: categoryId };
    updateStatus("update_filters", filters);
    getHouseType(categoryId);
    setShowAdsTypeSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
  };

  const handleHouseTypeOnClick = (houseType, subCategoryId) => {
    let filters = { houseType: houseType, subCategoryId: subCategoryId };
    updateStatus("update_filters", filters);
    setShowTypeHouseSlide("AuthProvinceCardClose ");
    setShowBackDrop(!showBackDrop);
    getOptions(subCategoryId);
  };

  const handleBoolTextFieldClick = (index) => {
    setActiveOption(index);
    setShowBackDrop(!showBackDrop);
    setShowSlide("AuthProvinceCardOpen ");
  };

  const handleAddOptionText = (
    index,
    optionId,
    stringValue,
    intValue,
    boolValue
  ) => {
    let newTransaction = state.filters.filter;
    let value = "";
    if (stringValue !== null) {
      value = { option_id: optionId, string: stringValue };
    }
    if (intValue !== null) {
      value = { option_id: optionId, int: intValue };
    }
    if (boolValue !== null) {
      value = { option_id: optionId, bool: boolValue };
    }
    newTransaction[index] = value;
    updateStatus("update_filters", { filter: [...newTransaction] });
  };

  const handleAddOptionPick = (
    index,
    optionId,
    stringValue,
    intValue,
    boolValue
  ) => {
    let newTransaction = state.filters.filter;
    let value = "";
    if (stringValue !== null) {
      value = { option_id: optionId, string: stringValue };
    }
    if (intValue !== null) {
      value = { option_id: optionId, int: intValue };
    }
    if (boolValue !== null) {
      value = { option_id: optionId, bool: boolValue };
    }
    newTransaction[index] = value;
    updateStatus("update_filters", { filter: [...newTransaction] });
    setShowSlide("AuthProvinceCardClose ");
    setShowBackDrop(false);
  };

  const handleArea = (value) => {
    props.subfilter("subfilter-area");
  };

  const handlePrice = (value) => {
    props.subfilter("subfilter-price");
  };
  return (
    <Row className={classes.Row}>
      <Col lg={6} sm={12}>
        <Textfield
          label="نوع آگهی"
          value={state.filters.category}
          customSelect
          className="w-100 my-2"
          InputProps={{
            readOnly: true,
          }}
          onClick={() => {
            setShowBackDrop(true);
            setShowAdsTypeSlide("AuthProvinceCardOpen ");
          }}
        />
      </Col>
      <Col lg={6} sm={12}>
        <Textfield
          label="نوع مسکن"
          value={state.filters.houseType}
          readOnly
          customSelect
          className="w-100 my-2"
          InputProps={{
            readOnly: true,
          }}
          onClick={() => {
            setShowBackDrop(true);
            setShowTypeHouseSlide("AuthProvinceCardOpen ");
          }}
        />
      </Col>

      <Col lg={6} sm={12}>
        <Textfield
          label="قیمت کل"
          unit="تومان"
          value={
            state.filters.minPrice
              ? `از ${state.filters.minPriceTitle} تا ${state.filters.maxPriceTitle}`
              : ""
          }
          InputProps={{
            readOnly: true,
          }}
          onClick={handlePrice}
          className="w-100 my-2"
        />
      </Col>

      <Col lg={6} sm={12}>
        <Textfield
          label="متراژ"
          unit="متر مربع"
          value={
            state.filters.minArea
              ? `از ${state.filters.minArea} تا ${state.filters.maxArea}`
              : ""
          }
          InputProps={{
            readOnly: true,
          }}
          onClick={handleArea}
          className="w-100 my-2"
        />
      </Col>

      {state.options.map((option, index) => {
        if (option.type === "1") {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                label={option.name}
                value={
                  state.filters.filter[index]
                    ? state.filters.filter[index].string
                    : ""
                }
                className="w-100 my-2"
                onChange={(value) => {
                  handleAddOptionText(
                    index,
                    option.id,
                    value.target.value,
                    null,
                    null
                  );
                }}
              />
            </Col>
          );
        } else if (option.type === "2") {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                label={option.name}
                value={
                  state.filters.filter[index]
                    ? state.filters.filter[index].int
                    : ""
                }
                type="number"
                className="w-100 my-2"
                onChange={(value) => {
                  handleAddOptionText(
                    index,
                    option.id,
                    null,
                    value.target.value,
                    null
                  );
                }}
              />
            </Col>
          );
        } else {
          return (
            <Col lg={6} sm={12} key={option.id.toString()}>
              <Textfield
                value={
                  state.filters.filter[index]
                    ? state.filters.filter[index].bool
                    : ""
                }
                InputProps={{
                  readOnly: true,
                }}
                label={option.name}
                customSelect
                onClick={() => handleBoolTextFieldClick(index)}
                className="w-100 my-2"
              />
            </Col>
          );
        }
      })}

      <Backdrop
        className={backDropClasses.backdrop}
        open={showBackDrop}
        onClick={() => {
          setShowAdsTypeSlide("AuthProvinceCardClose ");
          setShowTypeHouseSlide("AuthProvinceCardClose ");
          setShowSlide("AuthProvinceCardClose ");
          setShowBackDrop(!showBackDrop);
        }}
      ></Backdrop>

      <Card className={"pt-4 " + showAdsTypeSlide + classes.SlideCard}>
        <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
          نوع آگهی
        </h5>
        {state.category.map((item, index) => {
          return (
            <h6
              className="text-center my-2"
              key={index}
              onClick={() => handleCategoryOnClick(item.category, item.id)}
            >
              {item.category}
            </h6>
          );
        })}
      </Card>

      <Card className={"pt-4 " + showTypeHouseSlide + classes.SlideCard}>
        <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
          نوع مسکن
        </h5>
        {state.houseType.map((item, index) => {
          return (
            <h6
              className="text-center my-2"
              key={index}
              onClick={() => handleHouseTypeOnClick(item.sub_category, item.id)}
            >
              {item.sub_category}
            </h6>
          );
        })}
      </Card>

      <Card className={"pt-4 " + showSlide + classes.SlideCard}>
        <h5 className="text-center text-secondary mx-4 pb-3 border-bottom">
          {state.options.length > 0 ? state.options[activeOption].name : ""}
        </h5>
        <h6
          className="text-center my-2"
          onClick={() => {
            handleAddOptionPick(
              activeOption,
              state.options[activeOption].id,
              null,
              null,
              "بله"
            );
          }}
        >
          بله
        </h6>
        <h6
          className="text-center my-2"
          onClick={() => {
            handleAddOptionPick(
              activeOption,
              state.options[activeOption].id,
              null,
              null,
              "خیر"
            );
          }}
        >
          خیر
        </h6>
      </Card>
    </Row>
  );
};

const p2e = (s) => s.replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));

export default Filters;
