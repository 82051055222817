
export function calculatTimes(current) {
    const date_test = new Date(current.replace(/-/g,"/"));
    const d = new Date();
  
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
  
    var elapsed = d.getTime() - date_test.getTime();
  
    if (elapsed < msPerMinute) {
         return ' لحظاتی پیش';   
    }
  
    else if (elapsed < msPerHour) {
      if(elapsed < 900000){
        return ' یک ربع پیش';   
      }else if(elapsed < 1200000){
        return ' نیم ساعت پیش';   
      }else {
        return ' 1 ساعت پیش';   
      }
    }
  
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' ساعت پیش';   
    }
  
    else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' روز پیش';   
    }
  
    else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' ماه پیش';   
    }
  
    else {
        return Math.round(elapsed/msPerYear ) + ' سال پیش';   
    }
  }
  