import React from "react";
import { useParams } from "react-router-dom";

const ConsilingPages = () => {
  const { id } = useParams();
  console.log(id)
  return (
    <>
      <h1>hellooo </h1>
    </>
  );
};

export default ConsilingPages;
