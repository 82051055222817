import React from "react";
import { useHistory } from "react-router-dom";
import Card from "react-bootstrap/Card";

import classes from "./InboxItem.module.css";

const InboxItem = (props) => {
  const history = useHistory();
  
  const handleItemClick = ()=>{
    props.onCLick(props.ticket.message_thread_code);
    history.push('/ticket/detail');
}
  return (
    <Card className={"mt-2 " + classes.Container} onClick={handleItemClick} >
    {localStorage.getItem('id') === props.ticket.sender ? <h6>ارسال شده از طرف شما</h6> : <h6>ارسال شده از پشتیبان</h6>}
    <p>{props.ticket.subject}</p>
    </Card>
  );
};

export default InboxItem;
