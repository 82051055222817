import React,{useState,useContext,useEffect,useRef} from "react";
import { Context } from "../../Context/AdsContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteItem from "./FavoriteItem/FavoriteItem";

import classes from "./Favorites.module.css";


const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));
const Favorites = (props) => {
  const isInitialMount = useRef(true);
  const { state, getFavoriteAdsList,updateStatus } = useContext(Context);
  const [isFetchMore,setIsFetchMore] = useState(false);
  const [loadingStatus,setLoadingStatus] = useState(true);
  const progressClasses = useStylesProgress();


  
  useEffect(() => {
    getFavoriteAdsList(state.favoriteListPage);
    return ()=>{
      updateStatus('reset_favorite_ads_list',[]);
      updateStatus('last_favorite_list_page',1);
    }
  }, []);


  const scrollCheck = event => {
    const size1 = event.target.scrollHeight - event.target.offsetHeight;
    const bottom =  size1 - event.target.scrollTop;
    if (bottom <= 1) {
      if(!isFetchMore && state.isFavoriteListFetchMore){
        setIsFetchMore(true);
        getFavoriteAdsList((state.favoriteListPage)+1)
      }
    }
  };

  
  useEffect(()=>{
    setIsFetchMore(false);
  },[state.isFavoriteListFetchMore])

  useEffect(()=>{
    if (isInitialMount.current) {
      isInitialMount.current = false;
   } else {
    setIsFetchMore(false);
    setLoadingStatus(false);
   }
    
  },[state.favoriteAds])

  return (
    <Row className="m-0">
      <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
      <p className='m-0 text-white text-center flex-fill'>علاقه مندی ها</p>

      </Col>

      <Col sm={12} className={"pt-2 overflow-auto " + classes.Main} onScroll={scrollCheck}>
      <Row>
        {loadingStatus ? <div className={'flex-fill text-center pt-3'}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div> : null}
              
              {state.favoriteAds.length === 0 && !loadingStatus ? <div className={'flex-fill text-center pt-3'}>
              <h6 className={classes.NoData}>لیست علاقه مندی شما خالی می باشد</h6>
              </div> : null}
          {state.favoriteAds.map( (ads) => (
            <Col sm={12} md={4} key={ads.id.toString()}>
              <FavoriteItem ads={ads} />
            </Col>
          ))}
           {isFetchMore ? <div className={'flex-fill text-center pt-3 '}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div> : null}
        </Row>
      </Col>
    </Row>
  );
};

export default Favorites;
