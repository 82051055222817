import React,{useState,useEffect,useContext} from "react";
import {Context} from '../../../Context/AuthContext'
import { useHistory } from "react-router-dom";
import InboxItem from './InboxItem/InboxItem'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import classes from "./Inbox.module.css";


const useStylesProgress = makeStyles((theme) => ({
  colorPrimary: {
    color: '#2979FF'
  },
}));
const Inbox = (props) => {
  const history = useHistory();
  const {state,getTicketList,setActiveTicket} = useContext(Context);
  const progressClasses = useStylesProgress();

useEffect(()=>{
  getTicketList();
},[])
  const handleBackClick = ()=>{
    history.goBack();
}
  return (
    <Row className="m-0">
      <Col sm={12} className={'d-flex align-items-center '+classes.Header}>
      <ArrowForwardIcon size={24} className={classes.BackIcon} onClick={handleBackClick} />
      <p className='m-0 text-white text-center flex-fill'>صندوق ورودی</p>

      </Col>

      <Col sm={12} className={"pt-2 overflow-auto " + classes.Main} >
      <Row>
      {state.ticketList.length < 1 ? <div className={'flex-fill text-center pt-3'}>
              <CircularProgress color='primary' classes={progressClasses}/>
              </div> : null}

        {state.ticketList.map( ticket => (
            <Col sm={12} md={4} key={ticket.message_thread_code.toString()}>
              <InboxItem ticket={ticket} onCLick={(message_thread_code)=>setActiveTicket(message_thread_code)}/>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Inbox;
