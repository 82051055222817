import React, { useRef, useEffect, useState } from "react";
import Swiper from "swiper";
import "./CounselingSwiper.css";
import "swiper/swiper.min.css";
import AmlakApi from "../../../api/amlak";

const SwiperComponent = () => {
  const [getConsultant, setConsultant] = useState([]);
  const swiperRef = useRef(null);

  const getAllConsultantData = async () => {
    try {
      await AmlakApi.post(`Consultant/all`)
        .then(async (response) => {
          if (response?.data.status === "success") {
            await setConsultant(response?.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const swiperWrapper = document.querySelector(".swiper-wrapper");
    swiperWrapper.style.width = "100px";
    swiperWrapper.style.height = "100px";
    swiperWrapper.style.marginRight = "20px";
    // ایجاد یک نمونه از swiper
    swiperRef.current = new Swiper(".swiper-container", {
      direction: "horizontal",
      allowSlidePrev: false,
      loop: true,
      slidesPerView: 3,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      autoplay: {
        delay: 3000,
      },
      speed: 1000,
      effect: "slide",
    });

    getAllConsultantData();
  }, []);

  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">
        {getConsultant.map((elements) => {
          return (
            <div className="slide" key={elements.id}>
              <div
                className="circle-image"
                style={{
                  backgroundImage:
                    "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBYm7g_W9BtaVvrBtn1PAp1oZ59vHHfuIKOlHvXhgi&s)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                }}
              ></div>
              <p>{elements.first_name}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SwiperComponent;
// id: "1", first_name: "محسن", last_name: "یوسفی", description: "توضیحات مشاوره", phone: "09368021790", state: "آذربایجان شرقی", city: "تبريز", profile_picture
